var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("promised-modal-component", {
    ref: "modal",
    attrs: {
      "modal-settings": _vm.modalSettings,
      "reject-behavior": _vm.rejectBehavior,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function ({ select, close }) {
            return [_vm._t("header", null, { select: select, close: close })]
          },
        },
        {
          key: "default",
          fn: function ({ select, close }) {
            return [
              _c(
                "table-component",
                _vm._b(
                  { on: { "row-click": select } },
                  "table-component",
                  _vm.mergedTableSettings,
                  false
                )
              ),
              _vm._v(" "),
              _vm._t("default", null, { select: select, close: close }),
            ]
          },
        },
        {
          key: "footer",
          fn: function ({ select, close }) {
            return [_vm._t("footer", null, { select: select, close: close })]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }