<template>
    <div style="position:relative">
        <calendar-component ref="calendar" :is-range="isRange" :columns="$screens({desktop: 2}, 1)" :value="value" @input="$emit('input',$event)" @popoverWillShow="calendarVisible = true" @popoverWillHide="calendarVisible = false">
            <button class="button calendar-button" v-bind:class="[this.buttonClass, !(!calendarVisible || value === null)?'is-opened':'', dropdownIcon?'has-icons-right':'']" style="width:100%">
                <slot></slot>
                <icon-component v-if="dropdownIcon" :icon="calendarVisible?'fas fa-angle-up':'fas fa-angle-down'" class="is-right"></icon-component>
            </button>
        </calendar-component>
        <button v-if="showClear" ref="clear" class="button is-danger calendar-clear-button" v-bind:class="{'is-hidden':!calendarVisible || value === null}" @click="$emit('input',null)">
            <icon-component icon="fas fa-times"></icon-component>
            <span>{{$trans('Clear')}}</span>
        </button>
    </div>
</template>
<script>
module.exports = {
    name:'calendarButtonComponent',
    props:{
        isRange:{
            type:Boolean,
            default:false
        },
        value:{
            type:[Date,Array,Object],
            default:null
        },
        buttonClass:{},
        showClear:{
            type:Boolean,
            default:true,
        },
        dropdownIcon:{
            type:Boolean,
            default:true,
        }
    },
    data(){
        return {
            calendarVisible: false,
        }
    },
    mounted(){
        if(this.showClear){
            this.$refs.clear.style.right=(-this.$refs.clear.offsetWidth+1)+'px';
        }
    }
}
</script>