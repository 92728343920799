var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("svg", {
    staticClass: "is-jdenticon",
    attrs: {
      width: _vm.size,
      height: _vm.size,
      "data-jdenticon-value": _vm.value,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }