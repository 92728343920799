<template>
    <span v-if="showTooltip" class="tag" :class="statusClass" v-has-tooltip :content="translatedTooltip">
        {{ translatedStatus }}
    </span>
    <span v-else class="tag" :class="statusClass">
        {{ translatedStatus }}
    </span>
</template>
<script>
module.exports = {
    props:{
        status:{
            type:Object,
            validator: function (status) {
                return isset(()=>status.name)
            }
        }
    },
    computed:{
        translatedStatus(){
            if('is-verbose' in this.$attrs){
                return this.translatedTooltip;
            }
            if(!isset(()=>this.status.name))
                return undefined;
            return $trans(this.status.name);
        },
        translatedTooltip(){
            if(!isset(()=>this.status.descr))
                return undefined;
            return $trans(this.status.descr);
        },
        showTooltip(){
            if('no-tooltip' in this.$attrs || 'is-verbose' in this.$attrs)
                return false;
            return !!this.translatedTooltip;
        },
        statusClass(){
            if(isset(()=>this.status.style) && !!this.status.style)
                return 'is-'+this.status.style;
            return undefined;
        },
    }
}
</script>