var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "nav",
    { staticClass: "breadcrumb", attrs: { "aria-label": "breadcrumb" } },
    [
      _c(
        "ul",
        _vm._l(_vm.links, function (link, i) {
          return _c(
            "li",
            { key: i, class: i == _vm.links.length - 1 ? "is-active" : "" },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: link.link,
                    "aria-current": i == _vm.links.length - 1 ? "page" : false,
                  },
                },
                [
                  typeof link.icon !== "undefined"
                    ? [
                        _c("icon-component", { attrs: { icon: link.icon } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(link.name) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    : typeof link.deviceType !== "undefined"
                    ? [
                        _c("icon-component", {
                          attrs: { "device-type": link.deviceType },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(link.name) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    : [
                        _vm._v(
                          "\n                    " +
                            _vm._s(link.name) +
                            "\n                "
                        ),
                      ],
                ],
                2
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }