<template>
    <modal-component :is-card="true" @close="cancel" :title="messageHasTitle && !options.html?messageTitle:''">
        <template v-if="messageHasTitle && options.html" v-html="messageTitle" v-slot:header>
        </template>

        <p v-if="options.html" v-html="messageBody" class="block"></p>
        <p v-else-if="messageBody" class="block">{{messageBody}}</p>

        <table-component v-if="isTable" v-bind="options.table" :pagination_size_list="getIfIsset(()=>options.table.pagination_size_list, 10)" :searchable="getIfIsset(()=>options.table.searchable, false)" :refreshable="getIfIsset(()=>options.table.refreshable, false)" :pagination_informations="getIfIsset(()=>options.table.pagination_informations, false)" :url_params="getIfIsset(()=>options.table.url_params, false)" :clickable_rows="getIfIsset(()=>options.table.clickable_rows, true)" @row-click="tableClick"></table-component>
        <input v-else-if="isHardConfirm || isPrompt" class="input" :type="options.inputType" v-bind="options.inputAttributes" :placeholder="inputPlaceholder" v-model="input" ref="inputElem" :disabled="this.loading" v-on:keyup.enter.stop="submitInput">

        <template v-slot:footer>
            <div class="columns mx-0" id="alertButtons">
                <div v-if="leftButtonEnabled" class="column is-half">
                    <a v-if="leftButtonLink !== false" :href="leftButtonLink" @click.prevent="clickLeftBtn()" class="button is-fullwidth" v-bind:class="leftButtonClasses" :autofocus="leftBtnFocus" :disabled="leftButtonDisabled">
                        {{leftBtnText}}
                    </a>
                    <button v-else @click="clickLeftBtn()" class="button is-fullwidth" v-bind:class="leftButtonClasses" :autofocus="leftBtnFocus" :disabled="leftButtonDisabled">
                        {{leftBtnText}}
                    </button>
                </div>
                <div v-if="rightButtonEnabled" class="column is-half" v-bind:class="{'is-offset-half':!leftButtonEnabled}">
                    <a v-if="rightButtonLink !== false" :href="rightButtonLink" @click.prevent="clickRightBtn()" class="button is-fullwidth" v-bind:class="rightButtonClasses" :autofocus="rightBtnFocus" :disabled="rightButtonDisabled">
                        {{rightBtnText}}
                    </a>
                    <button v-else @click="clickRightBtn()" class="button is-fullwidth" v-bind:class="rightButtonClasses" :autofocus="rightBtnFocus" :disabled="rightButtonDisabled">
                        {{rightBtnText}}
                    </button>
                </div>
            </div>
        </template>
    </modal-component>
</template>

<script>
import DialogMixin from './../vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
import modalComponent from './modal-component.vue';
import tableComponent from './table-component.vue';

export default {
    mixins: [DialogMixin],
    components: {
        modalComponent,
        tableComponent
    },
    mounted(){
        if(isset(()=>this.$refs.inputElem)){
            this.$refs.inputElem.focus();
        }
    },
    methods: {
        submitInput(){
            if((this.options.reverse && !this.leftButtonClasses) || (!this.options.reverse && !this.rightButtonDisabled)){
                this.proceed(this.getDefaultData());
            }
        },
        tableClick(row){
            this.input = row;
            this.proceed(this.getDefaultData());
        }
    },
    computed: {     
        rightButtonEnabled(){
            if(this.isTable){
                return this.rightBtnComponent!=='ok-btn'
            }
            return this.rightBtnEnabled
        },
        
        leftButtonEnabled(){
            if(this.isTable){
                return this.leftBtnComponent!=='ok-btn'
            }
            return this.leftBtnEnabled
        },

        rightButtonClasses(){
            var ret = [];
            if(this.rightBtnComponent=='ok-btn'){
                if(this.loading){
                    ret.push('is-loading');
                }
                ret.push('is-success');
            }
            else{
                ret.push('is-danger');
            }
            return ret;
        },

        rightButtonDisabled(){
            if(this.isHardConfirm && this.rightBtnComponent=='ok-btn' && !this.loading){
                return this.input.localeCompare(this.options.verification, django.currentLang, {sensitivity: 'base'}) !== 0;
            }
            return this.rightBtnComponent=='cancel-btn' && this.loading;
        },

        rightButtonLink(){
            return (isset(()=>this.options.eventData.button_url) && this.rightBtnComponent=='ok-btn')?this.options.eventData.button_url:false;
        },

        leftButtonClasses(){
            var ret = [];
            if(this.leftBtnComponent=='ok-btn'){
                if(this.loading){
                    ret.push('is-loading');
                }
                ret.push('is-success');
            }
            else{
                ret.push('is-danger');
            }
            return ret;
        },

        leftButtonDisabled(){
            if(this.isHardConfirm && this.leftBtnComponent=='ok-btn' && !this.loading){
                return this.input.localeCompare(this.options.verification, django.currentLang, {sensitivity: 'base'}) !== 0;
            }
            return this.leftBtnComponent=='cancel-btn' && this.loading;
        },

        leftButtonLink(){
            return (isset(()=>this.options.eventData.button_url) && this.leftBtnComponent=='ok-btn')?this.options.eventData.button_url:false;
        },

        inputPlaceholder(){
            if(this.isPrompt && isset(()=>this.options.placeholder)){
                return this.options.placeholder;
            }
            if(this.isHardConfirm){
                return this.options.verification;
            }
            return '';
        },

        isTable(){
            return typeof this.options.table === 'object';
        }
    }
};
</script>

<style scoped>
#alertButtons {
    width: 100%;
}
#alertButtons .column {
    padding: 0 1.5rem 0.25rem 1.5rem;
}
</style>