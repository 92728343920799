<template>
    <conditional-wrapper :show="wrap">
        <span class="icon" v-if="icon === 'my-company'">
            <span class="fa-stack">
                <i class="far fa-building fa-stack-1x"  data-fa-transform="shrink-3 up-3 right-5"></i>
                <i class="fas fa-user fa-stack-1x"      data-fa-transform="down-1 left-4"></i>
            </span>
        </span>
        <span ref="iconWrapper" class="icon" v-bind:class="sizeClass" v-else>
            <i v-if="!slash" :class="sizedIcon"></i>
            <span v-else class="fa-stack" :class="slashSize">
                <i :class="sizedIcon"></i>
                <i v-if="slash" class="fas fa-slash fa-stack-1x fa-inverse" data-fa-transform="down-2.5"></i>
                <i v-if="slash" class="fas fa-slash fa-stack-1x"></i>
            </span>
        </span>
    </conditional-wrapper>
</template>
<script>
module.exports = {
    name:'iconComponent',
    props:{
        icon:{
            type: [String, Boolean],
            default: 'fas fa-question'
        },
        size:{
            type: String,
            default: 'S'
        },
        deviceType:{
            type: String,
            default: undefined
        },
        wrap:{
            type: Boolean,
            default: true,
        },
        slash:{
            type: Boolean,
            default: false,
        }
    },
    watch:{
        sizedIcon(nVal,oVal){
            if(this.$refs.iconWrapper.querySelector("[data-fa-i2svg]") != null){
                this.$refs.iconWrapper.querySelector("[data-fa-i2svg]").classList.remove(...oVal.split(' '));
                this.$refs.iconWrapper.querySelector("[data-fa-i2svg]").classList.add(...nVal.split(' '));
            }
        }
    },
    computed:{
        sizedIcon(){
            var ret = undefined;
            if(this.icon === true)
                ret = 'fas fa-check-square';
            else if(this.icon === false)
                ret = 'far fa-square';
            else if(typeof this.deviceIcon !== "undefined")
                ret = this.deviceIcon;
            else
                ret = this.icon.replace(/(\s|^)fa-(xs|sm|lg|\d+x)(\s|$)/,' ');
            if(this.slash){
                ret += ' fa-stack-1x';
            }
            else{
                switch(this.size){
                    case 'L':   ret += ' fa-3x'; break;
                    case 'M':   ret += ' fa-2x'; break;
                }
            }            
            return ret;
        },
        slashSize(){
            switch(this.size){
                case 'L':   return 'fa-3x';
                case 'M':   return 'fa-2x';
                case 'S':
                default:    return '';
            }
        },
        sizeClass(){
            switch(this.size){
                case 'L':   return 'is-large';
                case 'M':   return 'is-medium';
                case 'S':
                default:    return '';
            }
        },
        deviceIcon(){
            switch(this.deviceType){
                case 'mbs':         return 'fas fa-broadcast-tower';
                case 'seal_v1':     return 'fas fa-lock';
                case 'obd2':        return 'fas fa-tachometer-alt';
                case 'gps_tracker': return 'fas fa-globe-europe';
                case 'rfid_reader': return 'fas fa-wifi';
                case 'rfid_handler':return 'fas fa-server';
                case 'uwb_tag':     return 'fas fa-map-marker-alt';
                case 'uwb_anchor':  
                case 'uwb_main':    return 'fas fa-search-location';
                default:
                    if(typeof this.deviceType !== "undefined")
                        return 'fas fa-microchip';
            }
            return undefined;
        }
    }
}
</script>