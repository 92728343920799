<template>
    <div class="battery-indicator-wrapper">
        <icon-component icon="fas fa-battery-empty" size="L"></icon-component>
        <div class="battery-indicator" :style="batteryStyle"></div>
    </div>
</template>
<script>
module.exports = {
    name:'batteryIndicatorComponent',
    props:{
        value:{
            type: Number,
            default: 0.5,
            validator: function (value) {
                return value >= 0 && value <= 1;
            }
        }
    },
    computed:{
        batteryColor(){
            return 'blue';
        },
        batteryStyle(){
            const map = function(x, in_min, in_max, out_min, out_max) {
                return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
            }
            return {
                'background-color': 'hsl(' + map(this.value, 0, 1, 0, 120) + ', 100%, 35%)',
                'width': map(this.value, 0, 1, 4, 89) + '%'
            }
        }
    }
}
</script>
<style>
.battery-indicator-wrapper {
    width: fit-content;
    position: relative;
}

.battery-indicator-wrapper .icon svg {
    z-index: 1;
}

.battery-indicator-wrapper .battery-indicator {
    position: absolute;
    left: 1px;
    top: 16px;
    height: 16px;
    /* width: 122%;
    background-color: blue; */
    z-index: 0;
}
</style>