<template>
    <div>
        <div class="tabs" :class="tabsClass">
            <ul>
                <li v-for="(tab, i) of normalisedTabs" :key="i" :class="{'is-active':selected === i}">
                    <a @click="buttonOpenTab(i)">
                        <icon-component v-if="tab.icon !== null" :icon="tab.icon"></icon-component>
                        <span>{{tab.label}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div v-for="(tab, i) of normalisedTabs" :key="i" v-show="selected === i">
            <slot :name="tab.slot"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name:'tabsComponent',
    props:{
        'tabs-class':{
            default:'is-centered is-boxed'
        },
        tabs:{
            type:Array,
            required:true
        },
        url_params:{
            type:Boolean,
            default:false
        },
        switchable:{
            type:[Boolean, Function],
            default:true
        }
    },
    data(){
        return {
            selected:   undefined,
            preventBrowserUrlChange: true
        }
    },
    mounted(){
        if(this.url_params){
            window.onpopstate = this.popState;
            this.popState();
        }
    },
    computed:{
        normalisedTabs(){
            let ret = []
            for(const tab of this.tabs){
                let parsedTab = typeof tab === 'string' ? {
                    label:      tab,
                    slot:       tab,
                    default:    false,
                    icon:       null
                } : {
                    label:      window.getIfIsset(()=>tab.label),
                    slot:       window.getIfIsset(()=>tab.slot, tab.label),
                    default:    window.getIfIsset(()=>tab.default, false),
                    icon:       window.getIfIsset(()=>tab.icon, null),
                }
                ret.push(parsedTab)
            }
            const defaults = ret.filter((tab)=>tab.default)
            if(defaults.length === 0){
                ret[0].default = true
            }
            else if(defaults.length > 1){
                throw new Error('Only one tab can be set as default one')
            }
            if(typeof this.selected === 'undefined'){
                this.selected = ret.findIndex((tab)=>tab.default)
            }
            return ret
        }
    },
    methods:{
        buttonOpenTab(tab){
            if(this.switchable === true || (typeof this.switchable === 'function' && this.switchable(tab, this.selected))){
                this.openTab(tab)
            }            
        },
        openTab(tab){
            if(window.isset(()=>this.normalisedTabs[tab])){
                this.selected = tab
            }
            else{
                console.error('Unrecognised tab selected: '+tab)
            }
        },
        popState(){
            var state = !!window.location.search?window.location.search.substring(1).split('&').reduce((o,e)=>{e=e.split('='); o[decodeURIComponent(e[0])] = decodeURIComponent(e[1]); return o;},{}):{}
            if(window.isset(()=>state.tab)){
                state.tab = parseInt(state.tab)
            }
            else{
                state.tab = this.normalisedTabs.findIndex((tab)=>tab.default)
            }
            if(this.selected !== state.tab && window.isset(()=>this.normalisedTabs[state.tab])){
                this.preventBrowserUrlChange = true
                this.selected = state.tab
            }
        }
    },
    watch:{
        selected(){
            if(this.url_params){
                if(this.preventBrowserUrlChange){
                    this.preventBrowserUrlChange = false;
                    return;
                }
                history.pushState(null, "", '?'+Object.entries({tab: this.selected}).map(([key,val])=>`${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&'))
            }
            this.$emit('selected', this.selected)
        }
    }
}
</script>
