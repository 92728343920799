var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "calendar-component",
        {
          ref: "calendar",
          attrs: {
            "is-range": _vm.isRange,
            columns: _vm.$screens({ desktop: 2 }, 1),
            value: _vm.value,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event)
            },
            popoverWillShow: function ($event) {
              _vm.calendarVisible = true
            },
            popoverWillHide: function ($event) {
              _vm.calendarVisible = false
            },
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button calendar-button",
              class: [
                this.buttonClass,
                !(!_vm.calendarVisible || _vm.value === null)
                  ? "is-opened"
                  : "",
                _vm.dropdownIcon ? "has-icons-right" : "",
              ],
              staticStyle: { width: "100%" },
            },
            [
              _vm._t("default"),
              _vm._v(" "),
              _vm.dropdownIcon
                ? _c("icon-component", {
                    staticClass: "is-right",
                    attrs: {
                      icon: _vm.calendarVisible
                        ? "fas fa-angle-up"
                        : "fas fa-angle-down",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showClear
        ? _c(
            "button",
            {
              ref: "clear",
              staticClass: "button is-danger calendar-clear-button",
              class: {
                "is-hidden": !_vm.calendarVisible || _vm.value === null,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("input", null)
                },
              },
            },
            [
              _c("icon-component", { attrs: { icon: "fas fa-times" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$trans("Clear")))]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }