<template>
    <div class="loader-wrapper" v-bind:class="show?'is-active':''">
        <div class="loader is-loading"></div>
    </div>
</template>
<script>
export default {
    name:'loaderComponent',
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    mounted(){
        const opacity = 0.8;
        var color = this.getTrueBgColor(this.$el.parentElement);
        const reg = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
        var matchedColor = color.match(reg);
        if(matchedColor.length === 4){
            color = `rgba(${matchedColor[1]}, ${matchedColor[2]}, ${matchedColor[3]}, ${opacity})`;
        }
        this.$el.style.background = color;
    },
    methods:{
        getTrueBgColor(element){
            const empty = ['transparent', 'rgba(0,0,0,0)', 'rgba(0, 0, 0, 0)'];
            var this_color = window.getComputedStyle(element).backgroundColor;
            if(empty.indexOf(this_color) === -1){
                return this_color;
            }
            if(element.parentElement === null)
                return 'white';
            return this.getTrueBgColor(element.parentElement);
        }
    }
}
</script>