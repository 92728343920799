var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      Array.isArray(_vm.sheetSelectOptions) && _vm.sheetSelectOptions.length > 1
        ? _c("modal-component", { on: { close: _vm.clearImport } }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("h2", { staticClass: "subtitle" }, [
                  _vm._v(_vm._s(_vm.$trans("Wybierz arkusz do importu:"))),
                ]),
                _vm._v(" "),
                _c("input-component", {
                  attrs: { type: "select", options: _vm.sheetSelectOptions },
                  model: {
                    value: _vm.sheetSelect,
                    callback: function ($$v) {
                      _vm.sheetSelect = $$v
                    },
                    expression: "sheetSelect",
                  },
                }),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-fullwidth is-danger",
                        on: { click: _vm.clearImport },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$trans("Anuluj")) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-fullwidth is-success",
                        on: {
                          click: function ($event) {
                            return _vm.readWorkbookData(_vm.sheetSelect)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$trans("Wybierz")) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("article", { staticClass: "message mb-0" }, [
        _c(
          "div",
          {
            staticClass: "message-header is-clickable",
            on: {
              click: function ($event) {
                _vm.accordion = 0
              },
            },
          },
          [_c("p", [_vm._v(_vm._s(_vm.$trans("Import pliku")))])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.accordion === 0,
                expression: "accordion === 0",
              },
            ],
            staticClass: "message-body is-collapsible",
          },
          [
            _c(
              "div",
              { staticClass: "message-body-content" },
              [
                typeof _vm.label !== "undefined"
                  ? _c("label", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.label)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input-component", {
                  attrs: {
                    type: "file",
                    drag: true,
                    accept: ".xls,.xlsx,.xlsm,.xlsb,.ods,.csv,.txt",
                  },
                  on: { files: _vm.fileUpload },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("article", { staticClass: "message mb-0" }, [
        _c(
          "div",
          {
            staticClass: "message-header",
            staticStyle: {
              "border-radius": "0px",
              "border-top": "2px solid black",
            },
          },
          [_c("p", [_vm._v(_vm._s(_vm.$trans("Wybór danych")))])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.accordion === 1,
                expression: "accordion === 1",
              },
            ],
            staticClass: "message-body is-collapsible",
          },
          [
            _c(
              "div",
              { staticClass: "message-body-content" },
              [
                _vm.data.length
                  ? [
                      _c("input-component", {
                        attrs: {
                          type: "checkbox",
                          label: _vm.$trans(
                            "Importowane dane mają nagłówek (pomiń pierwszy wiersz)"
                          ),
                        },
                        model: {
                          value: _vm.importDataHasHeader,
                          callback: function ($$v) {
                            _vm.importDataHasHeader = $$v
                          },
                          expression: "importDataHasHeader",
                        },
                      }),
                      _vm._v(" "),
                      _c("table", { staticClass: "table is-fullwidth mb-4" }, [
                        _c("thead", [
                          typeof _vm.dataHeader !== "undefined"
                            ? _c(
                                "tr",
                                _vm._l(_vm.dataHeader, function (cell, i) {
                                  return _c(
                                    "th",
                                    {
                                      key: i,
                                      class: {
                                        "has-background-grey":
                                          _vm.selectedColumns[i] === null,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(cell) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "tr",
                            _vm._l(_vm.columnsCount, function (i) {
                              return _c(
                                "th",
                                {
                                  key: i - 1,
                                  class: {
                                    "has-background-grey":
                                      _vm.selectedColumns[i - 1] === null,
                                  },
                                },
                                [
                                  _c("input-component", {
                                    attrs: {
                                      type: "select",
                                      options: _vm.possibleColumns,
                                    },
                                    model: {
                                      value: _vm.selectedColumns[i - 1],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedColumns,
                                          i - 1,
                                          $$v
                                        )
                                      },
                                      expression: "selectedColumns[i-1]",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.displayData, function (row, i) {
                              return _c(
                                "tr",
                                { key: i },
                                _vm._l(row, function (cell, j) {
                                  return _c(
                                    "td",
                                    {
                                      key: j,
                                      class: {
                                        "has-background-grey":
                                          _vm.selectedColumns[j] === null,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(cell) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            }),
                            _vm._v(" "),
                            _vm.displayData.length !== _vm.rowsCount
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { attrs: { colspan: _vm.columnsCount } },
                                    [
                                      _c("icon-component", {
                                        attrs: { icon: "fas fa-ellipsis-h" },
                                      }),
                                      _c("br"),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "notification is-info p-2 pl-4 mb-2" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$trans(
                                  "Importowanych rekordów: %(records_count)s",
                                  { records_count: _vm.rowsCount }
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.missingRequiredColumns.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "notification is-danger p-2 pl-4 mb-2",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$trans(
                                      "Brakujące wymagane kolumny: %(missing_columns)s",
                                      {
                                        missing_columns:
                                          _vm.missingRequiredColumns
                                            .map(
                                              (column) =>
                                                `"${_vm.getIfIsset(
                                                  () => column.text,
                                                  column
                                                )}"`
                                            )
                                            .join(", "),
                                      }
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-success is-fullwidth",
                          attrs: {
                            disabled:
                              _vm.missingRequiredColumns.length !== 0 ||
                              _vm.rowsCount === 0,
                          },
                          on: { click: _vm.exportData },
                        },
                        [
                          _c("icon-component", {
                            attrs: { icon: "fas fa-save" },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$trans("Zatwierdź import"))),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _c("div", { staticClass: "notification is-warning" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$trans(
                              "Brak danych do wyboru! Dokonaj najpierw importu pliku w polu powyżej."
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("article", { staticClass: "message mb-5" }, [
        _c(
          "div",
          {
            staticClass: "message-header",
            staticStyle: {
              "border-radius": "0px",
              "border-top": "2px solid black",
            },
          },
          [_c("p", [_vm._v(_vm._s(_vm.$trans("Podsumowanie importu")))])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.accordion === 2,
                expression: "accordion === 2",
              },
            ],
            staticClass: "message-body is-collapsible",
          },
          [
            _c("div", { staticClass: "message-body-content" }, [
              _c("table", { staticClass: "table is-fullwidth" }, [
                _c(
                  "tbody",
                  [
                    _c("tr", [
                      _c("th", { staticClass: "is-narrow" }, [
                        _vm._v(_vm._s(_vm.$trans("Zaimportowanych rekordów:"))),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _vm._v(_vm._s(_vm.rowsCount)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tr",
                      [
                        _c(
                          "th",
                          {
                            staticClass: "is-narrow",
                            attrs: { rowspan: _vm.selectedGivenColumns.length },
                          },
                          [_vm._v(_vm._s(_vm.$trans("Przykładowy rekord:")))]
                        ),
                        _vm._v(" "),
                        _vm.rowsCount && _vm.selectedGivenColumns.length
                          ? [
                              _c("td", { staticClass: "is-narrow" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(_vm.selectedGivenColumns[0].text) +
                                      ":"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("h2", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.exampleRecord[
                                        _vm.selectedGivenColumns[0].value
                                      ]
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.rowsCount
                      ? _vm._l(
                          _vm.selectedGivenColumns.slice(1),
                          function (column, i) {
                            return _c("tr", { key: i }, [
                              _c("td", { staticClass: "is-narrow" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v(_vm._s(column.text) + ":"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("h2", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(_vm.exampleRecord[column.value])
                                  ),
                                ]),
                              ]),
                            ])
                          }
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }