const IS_DEBUG = process.env.NODE_ENV !== 'production'

import scrollIntoView from "scroll-into-view-if-needed"
import * as bulmaToast from "bulma-toast"
import VuejsDialog from "./vuejs-dialog/dist/vuejs-dialog.min.js"
import dialogComponent from './components/dialog-component.vue'
import cardComponent from './components/card-component.vue'
import conditionalWrapper from './components/conditional-wrapper.vue'
import dropdownComponent from './components/dropdown-component.vue'
import iconComponent from './components/icon-component.vue'
import inputComponent from './components/input-component.vue'
import loaderComponent from './components/loader-component.vue'
import modalComponent from './components/modal-component.vue'
import tableComponent from './components/table-component.vue'
import virtualKeyboardComponent from './components/virtual-keyboard-component.vue'
import promisedModalTableComponent from './components/promised-modal-table-component.vue'
import promisedModalComponent from './components/promised-modal-component.vue'
import csvImportComponent from './components/csv-import-component.vue'
import autoButtonComponent from './components/auto-button-component.vue'
import batchImportComponent from './components/batch-import-component.vue'
import tabsComponent from './components/tabs-component.vue'
import barcodeScannerComponent from './components/barcode-scanner-component.vue'
import XLSX from 'xlsx'
import {import_tools, getIfIsset} from './tools'

export default function init_custom_comps(Vue=getIfIsset(()=>window.Vue, null), skipComponents=[]){
    //js tools functions
    import_tools(Vue)

    //scrollIntoViewIfNeeded
    window.scrollIntoView = scrollIntoView;

    //bulma toast
    window.toast = function(message, type, duration, large=false){
        var msg_type;
        var icon_type;
        switch(type){
            case 'error':   msg_type = 'is-danger';     icon_type = 'fas fa-exclamation-circle'; break;

            case 'warning': msg_type = 'is-warning';    icon_type = 'fas fa-exclamation-triangle';   break;

            case 'success': msg_type = 'is-success';    icon_type = 'far fa-check-circle';  break;

            case 'debug':   msg_type = 'is-primary';    icon_type = 'fas fa-wrench';  break;

            case 'info':    msg_type = 'is-info';       icon_type = 'fas fa-info';    break;
            default:        break;
        }
        if(typeof duration !== 'number'){
            duration = Math.max(5000,message.split(" ").length*450);
        }
        if(large===true){
            //override message
            message = `
                <div style="height:100vh;" class="columns is-centered is-vcentered">
                    <div class="column is-half">
                        <i style="font-size:10em;" class="`+icon_type+`"></i>
                        <h1><b>`+message+`</b></h1>
                    </div>
                </div>
            `;
        } 
        bulmaToast.toast({
            message:        message,
            type:           msg_type,
            dismissible:    false,
            duration:       duration,
            position:       "bottom-center",
            animate: {
                in:         "bounceInUp",
                out:        "bounceOutDown",
                speed:      " "
            },
            pauseOnHover:   true
        });

        return new Promise((resolve)=>setTimeout(resolve, duration));
    };

    //xlsx
    window.XLSX = XLSX

    //vuejs dialog
    Vue.use(VuejsDialog,{
        view:           'dialog-component',
        okText:         'ok',
        cancelText:     'cancel',
        backdropClose:  true
    });
    Vue.dialog.registerComponent('dialog-component',dialogComponent)

    Vue.use({
        install: function(Vue){
            Vue.prototype.toast = window.toast
            Vue.prototype.$getUrl = window.$getUrl
        }
    });

    //vue components
    if(!skipComponents.includes('card-component'))
        Vue.component('card-component',cardComponent)

    if(!skipComponents.includes('conditional-wrapper'))
        Vue.component('conditional-wrapper',conditionalWrapper)

    if(!skipComponents.includes('dropdown-component'))
        Vue.component('dropdown-component',dropdownComponent)

    if(!skipComponents.includes('icon-component'))
        Vue.component('icon-component',iconComponent)

    if(!skipComponents.includes('input-component'))
        Vue.component('input-component',inputComponent)

    if(!skipComponents.includes('loader-component'))
        Vue.component('loader-component',loaderComponent)

    if(!skipComponents.includes('modal-component'))
        Vue.component('modal-component',modalComponent)

    if(!skipComponents.includes('table-component'))
        Vue.component('table-component',tableComponent)

    if(!skipComponents.includes('virtual-keyboard-component'))
        Vue.component('virtual-keyboard-component',virtualKeyboardComponent)

    if(!skipComponents.includes('promised-modal-table-component'))
        Vue.component('promised-modal-table-component',promisedModalTableComponent)

    if(!skipComponents.includes('promised-modal-component'))
        Vue.component('promised-modal-component',promisedModalComponent)

    if(!skipComponents.includes('csv-import-component'))
        Vue.component('csv-import-component',csvImportComponent)

    if(!skipComponents.includes('auto-button-component'))
        Vue.component('auto-button-component',autoButtonComponent)

    if(!skipComponents.includes('batch-import-component'))
        Vue.component('batch-import-component',batchImportComponent)
        
    if(!skipComponents.includes('tabs-component'))
        Vue.component('tabs-component',tabsComponent)

    if(!skipComponents.includes('barcode-scanner-component'))
        Vue.component('barcode-scanner-component',barcodeScannerComponent)
}