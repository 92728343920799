var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "field",
      class: { "has-addons": _vm.hasSlotData && !_vm.label },
    },
    [
      _vm.hasSlotData && !!_vm.label
        ? _c(
            "label",
            {
              ref: "label",
              staticClass: "label",
              class: _vm.labelClass,
              attrs: { for: "input_" + _vm._uid },
            },
            [
              _vm.labelIcon !== ""
                ? _c("icon-component", { attrs: { icon: _vm.labelIcon } })
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.label))]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "conditional-wrapper",
        { attrs: { show: _vm.hasSlotData && !!_vm.label } },
        [
          _c(
            "div",
            { staticClass: "field", class: { "has-addons": _vm.hasSlotData } },
            [
              _vm.type == "checkbox"
                ? _c(
                    "input",
                    _vm._g(
                      {
                        ref: "checkboxInput",
                        staticClass: "is-checkradio has-background-color",
                        class: _vm.realInputClass,
                        attrs: { id: "input_" + _vm._uid, type: "checkbox" },
                        domProps: { checked: _vm.value },
                      },
                      _vm.listeners
                    )
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.hasSlotData && !!_vm.label
                ? _c(
                    "label",
                    {
                      ref: "label",
                      staticClass: "label",
                      class: _vm.labelClass,
                      attrs: { for: "input_" + _vm._uid },
                    },
                    [
                      _vm.labelIcon !== ""
                        ? _c("icon-component", {
                            attrs: { icon: _vm.labelIcon },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.label))]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "radio"
                ? [
                    _vm._l(_vm.options, function (o, i) {
                      return [
                        _c(
                          "input",
                          _vm._g(
                            {
                              key: i + "_input",
                              ref: "radioInputs",
                              refInFor: true,
                              staticClass: "is-checkradio",
                              class: _vm.realInputClass,
                              attrs: {
                                id: "input_" + _vm._uid + "_" + i,
                                type: "radio",
                                name: "radio_" + _vm._uid,
                              },
                              domProps: {
                                value: _vm.getIfIsset(() => o.value, o),
                                checked:
                                  _vm.value == _vm.getIfIsset(() => o.value, o),
                              },
                            },
                            _vm.listeners
                          )
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            key: i + "_label",
                            staticClass: "ml-0",
                            attrs: { for: "input_" + _vm._uid + "_" + i },
                          },
                          [_vm._v(_vm._s(_vm.getIfIsset(() => o.text, o)))]
                        ),
                      ]
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !!_vm.$slots.before
                ? _c("div", { staticClass: "control" }, [_vm._t("before")], 2)
                : _vm._e(),
              _vm._v(" "),
              _vm.type != "checkbox" && _vm.type != "radio"
                ? _c(
                    "div",
                    { staticClass: "control", class: _vm.controlClass },
                    [
                      _vm.type == "textarea"
                        ? [
                            _c(
                              "textarea",
                              _vm._g(
                                _vm._b(
                                  {
                                    ref: "textareaInput",
                                    staticClass: "textarea",
                                    class: _vm.realInputClass,
                                    attrs: {
                                      id: "input_" + _vm._uid,
                                      placeholder: _vm.placeholder,
                                    },
                                    domProps: { value: _vm.value },
                                  },
                                  "textarea",
                                  _vm.bindAttrs,
                                  false
                                ),
                                _vm.listeners
                              )
                            ),
                          ]
                        : _vm.type == "autocomplete"
                        ? [
                            _c(
                              "input",
                              _vm._g(
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.autocomplete,
                                        expression: "autocomplete",
                                      },
                                    ],
                                    ref: "autocompleteInput",
                                    staticClass: "input",
                                    class: _vm.realInputClass,
                                    attrs: {
                                      id: "input_" + _vm._uid,
                                      type: "text",
                                      placeholder: _vm.placeholder,
                                      autocomplete: "nope",
                                    },
                                    domProps: { value: _vm.autocomplete },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.autocomplete = $event.target.value
                                      },
                                    },
                                  },
                                  "input",
                                  _vm.bindAttrs,
                                  false
                                ),
                                _vm.listeners
                              )
                            ),
                            _vm._v(" "),
                            _vm.realLeftIcon !== ""
                              ? _c("icon-component", {
                                  staticClass: "is-left",
                                  attrs: { icon: _vm.realLeftIcon },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.realRightIcon !== ""
                              ? _c("icon-component", {
                                  staticClass: "is-right",
                                  attrs: { icon: _vm.realRightIcon },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown is-fullwidth",
                                class: { "is-active": _vm.showAutocomplete },
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: {
                                      id: "dropdown-menu",
                                      role: "menu",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "dropdown-content dropdown-scroll",
                                      },
                                      [
                                        _c("loader-component", {
                                          attrs: {
                                            show: _vm.autocompleteIsLoading,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.autocompleteIsLoading
                                          ? _c("div", {
                                              staticStyle: { height: "100px" },
                                            })
                                          : [
                                              _vm.autocompleteAnyAllowed &&
                                              _vm.autocomplete !== ""
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectAutocomplete(
                                                            _vm.autocomplete,
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm.$trans(
                                                              _vm.autocompleteAnyAllowedString,
                                                              {
                                                                autocomplete:
                                                                  _vm.autocomplete,
                                                              }
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.filteredAutocompleteOptions,
                                                function (o, i) {
                                                  return [
                                                    typeof o == "string"
                                                      ? _c(
                                                          "a",
                                                          {
                                                            key: i,
                                                            ref: "autocompleteElements",
                                                            refInFor: true,
                                                            staticClass:
                                                              "dropdown-item",
                                                            class: {
                                                              "is-hover":
                                                                i ==
                                                                _vm.autocompleteKeyboardSelect,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectAutocomplete(
                                                                  o
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(o) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm.isset(
                                                          () => o.text
                                                        ) &&
                                                        _vm.isset(() => o.value)
                                                      ? _c(
                                                          "a",
                                                          {
                                                            key: i,
                                                            ref: "autocompleteElements",
                                                            refInFor: true,
                                                            staticClass:
                                                              "dropdown-item",
                                                            class: {
                                                              "is-hover":
                                                                i ==
                                                                _vm.autocompleteKeyboardSelect,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectAutocomplete(
                                                                  o
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.isset(
                                                              () => o.icon
                                                            )
                                                              ? _c(
                                                                  "icon-component",
                                                                  _vm._b(
                                                                    {},
                                                                    "icon-component",
                                                                    o.icon,
                                                                    false
                                                                  )
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(o.text)
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _vm.autocompleteMoreOptionsString !==
                                                false &&
                                              _vm.autocompleteAvailableOptions >
                                                _vm.autocompleteMaxOptions
                                                ? [
                                                    _c("hr", {
                                                      staticClass:
                                                        "dropdown-divider",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-item has-text-centered has-background-grey-lighter",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$trans(
                                                                _vm.autocompleteMoreOptionsString ===
                                                                  true
                                                                  ? "Dostępne jest więcej rekordów, aby je wyświetlić zawęź wyszukiwanie..."
                                                                  : _vm.autocompleteMoreOptionsString
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm
                                                    .filteredAutocompleteOptions
                                                    .length === 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-item has-text-centered has-background-grey-lighter",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm.$trans(
                                                              "Brak rekordów pasujących do tego zapytania!"
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm.type == "select"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "select is-fullwidth",
                                class: _vm.realInputClass,
                              },
                              [
                                _c(
                                  "select",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        ref: "selectInput",
                                        attrs: { id: "input_" + _vm._uid },
                                      },
                                      "select",
                                      _vm.bindAttrs,
                                      false
                                    ),
                                    _vm.listeners
                                  ),
                                  [
                                    !!_vm.placeholder &&
                                    !_vm.isset(() => this.$attrs.multiple)
                                      ? _c(
                                          "option",
                                          {
                                            attrs: {
                                              value: "",
                                              disabled: "",
                                              selected: "",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.placeholder))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(_vm.options, function (o, i) {
                                      return [
                                        typeof o == "string"
                                          ? _c(
                                              "option",
                                              {
                                                key: i,
                                                domProps: {
                                                  value: o,
                                                  selected: _vm.value == o,
                                                },
                                              },
                                              [_vm._v(_vm._s(o))]
                                            )
                                          : _vm.isset(() => o.text) &&
                                            _vm.isset(() => o.value)
                                          ? _c(
                                              "option",
                                              {
                                                key: i,
                                                attrs: {
                                                  disabled: _vm.getIfIsset(
                                                    () => o.disabled,
                                                    false
                                                  ),
                                                },
                                                domProps: {
                                                  value: o.value,
                                                  selected:
                                                    _vm.value == o.value,
                                                },
                                              },
                                              [_vm._v(_vm._s(o.text))]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.realLeftIcon !== ""
                              ? _c("icon-component", {
                                  staticClass: "is-left",
                                  attrs: { icon: _vm.realLeftIcon },
                                })
                              : _vm._e(),
                          ]
                        : _vm.type == "file"
                        ? [
                            _c(
                              "div",
                              {
                                ref: "fileInputWrapper",
                                staticClass: "file",
                                class: _vm.realInputClass,
                              },
                              [
                                _c("label", { staticClass: "file-label" }, [
                                  _c(
                                    "input",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          ref: "fileInput",
                                          staticClass: "file-input",
                                          attrs: { type: "file" },
                                        },
                                        "input",
                                        _vm.bindAttrs,
                                        false
                                      ),
                                      _vm.listeners
                                    )
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "file-cta" },
                                    [
                                      _vm.realLeftIcon !== ""
                                        ? _c("icon-component", {
                                            staticClass: "file-icon",
                                            attrs: { icon: _vm.realLeftIcon },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isset(
                                        () => _vm.$attrs["button-label"]
                                      )
                                        ? _c(
                                            "span",
                                            { staticClass: "file-label" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$attrs["button-label"]
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "file-label" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$trans(
                                                      "Wybierz plik..."
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.isset(() => _vm.$attrs.filename)
                                    ? _c(
                                        "span",
                                        { staticClass: "file-name" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(_vm.$attrs.filename) +
                                              "\n                                "
                                          ),
                                          _vm.realRightIcon !== ""
                                            ? _c("icon-component", {
                                                staticClass: "is-right",
                                                attrs: {
                                                  icon: _vm.realRightIcon,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        : _vm.type == "date" ||
                          _vm.type == "daterange" ||
                          _vm.type == "time" ||
                          _vm.type == "timerange" ||
                          _vm.type == "datetime" ||
                          _vm.type == "datetimerange"
                        ? [
                            _c(
                              "bulma-calendar",
                              _vm._g(
                                _vm._b(
                                  {
                                    ref: "calendarInput",
                                    attrs: {
                                      type: _vm.type.replace("range", ""),
                                      range: _vm.type.includes("range"),
                                      value: _vm.value,
                                    },
                                  },
                                  "bulma-calendar",
                                  _vm.bindAttrs,
                                  false
                                ),
                                _vm.listeners
                              )
                            ),
                          ]
                        : [
                            _c(
                              "input",
                              _vm._g(
                                _vm._b(
                                  {
                                    ref: "defaultInput",
                                    staticClass: "input",
                                    class: _vm.realInputClass,
                                    attrs: {
                                      id: "input_" + _vm._uid,
                                      type: _vm.type,
                                      placeholder: _vm.placeholder,
                                    },
                                    domProps: { value: _vm.value },
                                  },
                                  "input",
                                  _vm.bindAttrs,
                                  false
                                ),
                                _vm.listeners
                              )
                            ),
                            _vm._v(" "),
                            _vm.realLeftIcon !== ""
                              ? _c("icon-component", {
                                  staticClass: "is-left",
                                  attrs: { icon: _vm.realLeftIcon },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.realRightIcon !== ""
                              ? _c("icon-component", {
                                  staticClass: "is-right",
                                  attrs: { icon: _vm.realRightIcon },
                                })
                              : _vm._e(),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !!_vm.$slots.default
                ? _c("div", { staticClass: "control" }, [_vm._t("default")], 2)
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessage
                ? _c(
                    "p",
                    { staticClass: "help is-danger" },
                    [
                      Array.isArray(_vm.error)
                        ? _vm._l(_vm.error, function (msg) {
                            return _c("span", { key: msg }, [
                              _c("p", [_vm._v(_vm._s(msg))]),
                            ])
                          })
                        : [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.error) +
                                "\n                "
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.successMessage
                ? _c(
                    "p",
                    { staticClass: "help is-success" },
                    [
                      Array.isArray(_vm.success)
                        ? _vm._l(_vm.success, function (msg) {
                            return _c("span", { key: msg }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(msg) +
                                  "\n                    "
                              ),
                            ])
                          })
                        : [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.success) +
                                "\n                "
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }