var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      typeof _vm.summary !== "undefined" && _vm.summary.status >= 300
        ? _c(
            "modal-component",
            {
              attrs: {
                "is-card": true,
                title: _vm.$trans("Wystąpił błąd podczas importu danych"),
              },
              on: {
                close: function ($event) {
                  _vm.summary = undefined
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "button is-fullwidth is-danger",
                            on: {
                              click: function ($event) {
                                _vm.summary = undefined
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$trans("Zamknij")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                632797542
              ),
            },
            [
              Array.isArray(_vm.summary.data) &&
              _vm.summary.data.length === _vm.data.length
                ? [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(_vm.$trans("Rekordów zawierających błędy:")) +
                          " " +
                          _vm._s(_vm.erroredRows)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(_vm.$trans("Poprawnych rekordów:")) +
                              " " +
                              _vm._s(_vm.data.length - _vm.erroredRows)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.data.length - _vm.erroredRows
                        ? _c("div", { staticClass: "column" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "button is-fullwidth is-success is-small",
                                attrs: {
                                  disabled:
                                    _vm.data.length - _vm.erroredRows === 0,
                                },
                                on: { click: _vm.skipErrors },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$trans(
                                        "Ignoruj błędy, zaimportuj %(count)s poprawnych rekordów",
                                        {
                                          count:
                                            _vm.data.length - _vm.erroredRows,
                                        }
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "columns is-vcentered" }, [
                      _c("div", { staticClass: "column is-narrow" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$trans("Pokaż rekordy:"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("input-component", {
                            attrs: {
                              options: [
                                { text: _vm.$trans("Wszystkie"), value: 0 },
                                { text: _vm.$trans("Tylko błędne"), value: 1 },
                                {
                                  text: _vm.$trans("Tylko poprawne"),
                                  value: 2,
                                },
                              ],
                              type: "select",
                            },
                            model: {
                              value: _vm.rowsFilter,
                              callback: function ($$v) {
                                _vm.rowsFilter = $$v
                              },
                              expression: "rowsFilter",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("table-component", {
                      attrs: {
                        searchable: false,
                        url_params: false,
                        pagination_informations: false,
                        columns: _vm.errorsColumns(),
                        data: _vm.errorsData,
                      },
                    }),
                    _vm._v(" "),
                    _c("h2", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$trans("Popraw powyższe błędy i spróbuj ponownie")
                        )
                      ),
                    ]),
                  ]
                : [
                    _c("h2", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$trans(
                            "Wystąpił nierozpoznany błąd, spróbuj ponownie"
                          )
                        )
                      ),
                    ]),
                  ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      typeof _vm.summary !== "undefined" && _vm.summary.status === 200
        ? _c(
            "modal-component",
            {
              attrs: {
                "is-card": true,
                title: _vm.$trans("Poprawny import danych"),
              },
              on: {
                close: function ($event) {
                  _vm.summary = undefined
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "button is-fullwidth is-success",
                            attrs: { href: _vm.redirectUrl },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$trans("Zamknij")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3644741256
              ),
            },
            [
              _c("label", { staticClass: "label" }, [
                _vm._v(
                  _vm._s(_vm.$trans("Zaimportowanych rekordów:")) +
                    " " +
                    _vm._s(_vm.summary.data.count)
                ),
              ]),
              _vm._v(" "),
              _c("table-component", {
                attrs: {
                  searchable: false,
                  url_params: false,
                  pagination_informations: false,
                  columns: _vm.columns,
                  data: _vm.data,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader-component", { attrs: { show: _vm.loader } }),
      _vm._v(" "),
      _c(
        "csv-import-component",
        _vm._b(
          {
            ref: "csv",
            on: {
              data: (e) => (_vm.data = e),
              columns: (e) => (_vm.columns = _vm.translateColumns(e)),
            },
          },
          "csv-import-component",
          _vm.csvSettings,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-fullwidth is-primary",
          attrs: {
            disabled: !Array.isArray(_vm.data) || _vm.data.length === 0,
          },
          on: { click: _vm.save },
        },
        [
          _c("icon-component", { attrs: { icon: "fas fa-save" } }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$trans("Importuj")))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }