<template>
    <dynamic-content-container ref="content">
        <loader-component :show="loading"></loader-component>
        <slot></slot>
    </dynamic-content-container>
</template>
<script>
module.exports = {
    name:'dashboardComponent',
    props:{
        name:{
            type:       String,
            required:   true
        }
    },
    computed:{
        prefixedName(){
            return 'dashboard_settings_'+this.name;
        }
    },
    data(){
        return {
            loading:    true,
            settings:   {}
        }
    },
    async mounted(){
        //todo - validate children (component types + unique id's)
        try{
            var temp_settings = (await axios.get($getUrl('backendCustomisedGet',{name:this.prefixedName}))).data.value;
            var cards = this.$refs.content.$children.filter((e)=>e.$options._componentTag=="dynamic-card-component");
            if(cards.length != Object.keys(temp_settings).length){
                //todo - maybe not? this will fail if child is hidden with v-if
                throw new Error("Config from server does not match DOM!");
            }
            for(var id of Object.keys(temp_settings)){
                var this_card = cards.filter((e)=>e.$attrs.id==id);
                if(this_card.length != 1){
                    throw new Error("Config from server does not match DOM!");
                }
                this.setElementSettings(this_card[0], temp_settings[id]);
            }
            this.settings = temp_settings;
        }
        catch(e){
            console.error(e);

            var temp_settings = {};
            var cards = this.$refs.content.$children.filter((e)=>e.$options._componentTag=="dynamic-card-component");
            for(var element of cards){
                temp_settings[element.$attrs.id] = this.getElementSettings(element);
            }
            this.settings = temp_settings;
        }
        this.$nextTick(()=>{
            this.loading = false;
        });
    },
    methods:{
        childResized(child){
            this.settings[child.$attrs.id] = this.getElementSettings(child);
        },
        getElementSettings(element){
            return {
                v:  true,
                x:  element.x,
                y:  element.y,
                w:  element.width,
                h:  element.height
            };
        },
        setElementSettings(element, settings){
            //todo - handle "v" param
            element.x = settings.x;  
            element.y = settings.y;  
            element.width = settings.w;
            element.height = settings.h;
        },
        async saveSettings(){
            var mineLoader = true;
            if(this.loading){
                mineLoader = false;
            }
            else{
                this.loading = true;
            }
            await axios.post($getUrl('backendCustomisedSet'),{name:this.prefixedName,value:this.settings});
            if(mineLoader){
                this.loading = false;
            }
        }
    },
    watch:{
        settings:{
            deep:   true,
            handler: async function(){
                if(!this.loading){
                    await this.saveSettings();
                }
            }
        }
    }
}
</script>