<template>
    <span v-if="isset(()=>details.status) && details.status !== null">{{details.status}}</span>
    <span v-else-if="isset(()=>details.orientation) && details.orientation !== null">{{details.orientation}}</span>
    <a v-else-if="isset(()=>details.user) && details.user !== null" class="button is-link is-outlined" :href="$getUrl('frontend:my_company:users:single',{userId: details.user.id})">
        <jdenticon-component :value="details.user.email" :size="30"/>
        <span>
            {{details.user.email}}
        </span>
    </a>
    <a v-else-if="isset(()=>details.permanent) && details.permanent !== null" class="button is-link is-outlined" href="#">
        <jdenticon-component :value="details.permanent.token" :size="30"/>
        <span class="is-family-monospace">
            {{details.permanent.token}}
        </span>
    </a>
    <a v-else-if="isset(()=>details.reader)" class="button is-link is-outlined" :href="$getUrl('frontend:devices:single',details.reader.id)">
        <icon-component :device-type="details.reader.devicetype.code"></icon-component>
        <span>{{details.reader.uid}}</span>
    </a>
    <span v-else-if="isset(()=>details.params)">
        <modal-component v-if="configVisible" @close="configVisible = false" :is-card="true">
            <card-component>
                <h1 class="title">{{$trans("New configuration:")}}</h1>
                <configuration-component :current-device-configuration="details.params" :read-only="true"></configuration-component>
                <div class="is-divider"></div>
                <div class="columns" id="alertButtons">
                    <div class="column is-half is-offset-half">
                        <button @click="configVisible = false" class="button is-fullwidth is-success">
                            {{$trans("OK")}}
                        </button>
                    </div>
                </div>
            </card-component>
        </modal-component>
        <button class="button is-link is-outlined" @click="configVisible = true">
            <icon-component icon="fas fa-cogs"></icon-component>
            <span>
                {{$trans("Show new configuration")}}
            </span>
        </button>
    </span>
    <span v-else-if="isset(()=>details.reason)">{{$trans("Reset reason:")}} {{details.reason}}</span>
    <span v-else-if="details !== null">{{details}}</span>
</template>
<script>
module.exports = {
    name:'eventDetails',
    props:{
        details:{
            type:Object,
            default:()=>({})
        }
    },
    data(){
        return{
            configVisible:  false
        }
    }
}
</script>