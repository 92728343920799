var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      { staticClass: "column is-narrow" },
      [
        _c("input-component", {
          attrs: {
            label: _vm.$trans("X:"),
            type: "number",
            step: "0.01",
            disabled: _vm.inputsDisabled,
          },
          model: {
            value: _vm.deviceLocation.x,
            callback: function ($$v) {
              _vm.$set(_vm.deviceLocation, "x", $$v)
            },
            expression: "deviceLocation.x",
          },
        }),
        _vm._v(" "),
        _c("input-component", {
          attrs: {
            label: _vm.$trans("Y:"),
            type: "number",
            step: "0.01",
            disabled: _vm.inputsDisabled,
          },
          model: {
            value: _vm.deviceLocation.y,
            callback: function ($$v) {
              _vm.$set(_vm.deviceLocation, "y", $$v)
            },
            expression: "deviceLocation.y",
          },
        }),
        _vm._v(" "),
        _c(
          "input-component",
          {
            attrs: {
              label: _vm.$trans("Z:"),
              type: "number",
              step: "0.01",
              disabled: _vm.inputsDisabled,
            },
            model: {
              value: _vm.deviceLocation.z,
              callback: function ($$v) {
                _vm.$set(_vm.deviceLocation, "z", $$v)
              },
              expression: "deviceLocation.z",
            },
          },
          [
            _c(
              "button",
              {
                staticClass: "button is-danger",
                attrs: { disabled: _vm.inputsDisabled },
                on: {
                  click: function ($event) {
                    _vm.deviceLocation.z = null
                  },
                },
              },
              [_c("icon-component", { attrs: { icon: "fas fa-times" } })],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "button is-info is-fullwidth",
              on: {
                click: function ($event) {
                  _vm.selectOnMap = !_vm.selectOnMap
                },
              },
            },
            [
              _c("icon-component", { attrs: { icon: "fas fa-map" } }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$trans("Select location on map"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-success is-fullwidth",
              class: { "is-loading": _vm.loader },
              attrs: { disabled: _vm.noChanges },
              on: { click: _vm.saveLocation },
            },
            [
              _c("icon-component", { attrs: { icon: "fas fa-save" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$trans("Save device location")))]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("label", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$trans("Location preview on map:"))),
        ]),
        _vm._v(" "),
        _c("map-component", {
          ref: "map",
          attrs: {
            height: "400px",
            internal: _vm.location,
            "skip-devices": true,
          },
          on: { "location-inside-click": _vm.click },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }