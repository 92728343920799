var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "keyboardCard",
      staticClass: "card",
      class: { "is-active": _vm.isActive },
      attrs: { id: "keyboard-card" },
    },
    [
      _c(
        "div",
        {
          staticClass: "card-content",
          staticStyle: { width: "75%", margin: "auto" },
        },
        _vm._l(_vm.currentKeys, function (keysRow, i) {
          return _c(
            "div",
            { key: i, staticClass: "columns" },
            _vm._l(keysRow, function (key, j) {
              return _c("div", { key: j, staticClass: "column p-1" }, [
                _c("button", {
                  staticClass: "button is-fullwidth is-dark",
                  class: _vm.keysClasses[i][j],
                  domProps: { innerHTML: _vm._s(key) },
                  on: {
                    click: function ($event) {
                      return _vm.click(_vm.keys[i][j])
                    },
                  },
                }),
              ])
            }),
            0
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }