var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "dynamic-wrapper",
      class: _vm.dynamicClasses,
    },
    [
      _c(
        "card-component",
        {
          attrs: {
            "disable-resize": _vm.resizeDisabled,
            "is-opened": true,
            "is-loading": _vm.isLoading,
            "is-closable": true,
            "is-dragable": true,
            "is-movable": true,
            "is-resizable": true,
          },
          on: {
            close_click: function ($event) {
              return _vm.$emit("close_click")
            },
            "mouse-down": _vm.mousedown,
            "mouse-move": _vm.mousemove,
            "mouse-up": _vm.mouseup,
            "init-resize": _vm.resizeInit,
            "save-resize": _vm.signalResized,
            "mobile-show": _vm.mobileShowHandle,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [_vm._t("header")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [_vm._v(" "), _vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }