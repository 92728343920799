<template>
    <div></div>
</template>
<script>
module.exports = {
    name:'sliderComponent',
    props:{
        options:{
            type: Object,
            default: ()=>({
                start: 3,
                range:{
                    min: 0,
                    max: 10
                }
            })
        }
    },
    methods:{
        init(){
            if(this.optionsValid){
                if(typeof this.slider !== 'undefined'){
                    //destroy or update prev instance
                    this.slider.updateOptions(this.options);
                }
                else{
                    //init new instance
                    this.slider = noUiSlider.create(this.$el, this.options);
                    this.slider.on('update',(e)=>{
                        if(!this.noEmit){
                            if(e.length === 1){
                                this.$emit('value',e[0]);    
                            }
                            else{
                                this.$emit('value',e);
                            }
                        }
                    });
                }
            }
        }
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            slider: undefined,
            noEmit: false
        }
    },
    watch:{
        'options.start': function(newVal, oldVal){
            if(typeof this.slider !== 'undefined'){
                if((Array.isArray(newVal) !== Array.isArray(oldVal)) || (Array.isArray(newVal) && Array.isArray(oldVal) && newVal.length !== oldVal.length)){
                    this.slider.destroy();
                    this.slider = undefined;
                    this.init();
                }
                else{
                    this.noEmit = true;
                    this.slider.set(this.options.start);
                    this.$nextTick(()=>{
                        this.noEmit = false;
                    });
                }
            }
            else if(this.optionsValid){
                this.init();
            }
        },
        'options.range': function(){
            if(this.optionsValid){
                this.init();
            }
        },
        'options.step': function(){
            if(this.optionsValid){
                this.init();
            }
        },
    },
    computed:{
        optionsValid(){
            return isset(()=>this.options.start);
        }
    }
}
</script>