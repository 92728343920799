<template>
    <div style="position: relative">
        <template v-if="fallback === false">
            <promised-modal-table-component ref="modal" :tableSettings="modalTableSettings" :modalSettings="{title:$trans('Select camera to use'), 'is-card':true}"></promised-modal-table-component>
            <label class="label" v-if="label">{{label}}</label>
            <div id="reader"></div>
            <button class="button is-white is-small" v-if="cameras.length > 1" style="position: absolute; bottom: 10px; right: 10px;" @click="changeCamera">
                <icon-component icon="fas fa-camera"></icon-component>
                <span>{{$trans('Different camera')}}</span>
            </button>
            <button class="button is-white is-small" style="position: absolute; bottom: 10px; left: 10px;" @click="switchManual">
                <icon-component icon="fas fa-pencil-alt"></icon-component>
                <span>{{$trans('Manual input')}}</span>
            </button>
        </template>
        <template v-else>
            <button v-if="cameras.length" class="button is-primary is-outlined" @click="switchAuto">
                <icon-component icon="fas fa-camera"></icon-component>
                <span>{{$trans('Scan code')}}</span>
            </button>
            <p v-if="fallback !== true" class="help is-danger">
                {{ fallback }}
            </p>
            <input-component :label="$trans(fallbackLabel)" v-model="fallbackValue" @keyup.enter="sendFallback" v-bind="fallbackInput">
                <button class="button is-primary is-outlined" @click="sendFallback" :disabled="!enabled">
                    <icon-component icon="fas fa-save"></icon-component>
                    <span>{{$trans('Save')}}</span>
                </button>
            </input-component>
        </template>
    </div>
</template>
<script>
import inputComponent from './input-component.vue'
import promisedModalTableComponent from './promised-modal-table-component.vue'
import { Howl } from 'howler'
import scanSound from './../assets/scan.mp3'

export default {
    name:'barcodeScannerComponent',
    props:{
        label:{
            type: String,
            default: "Scan the barcode:"
        },
        fallbackLabel:{
            type: String,
            default: "Enter the barcode:"
        },
        scanValidation:{
            type: Function,
            default: ()=>true
        },
        scanParser:{
            type: Function,
            default: (text)=>text
        },
        playSound:{
            type: Boolean,
            default: true
        },
        enabled:{
            type: Boolean,
            default: true
        },
        fallbackInput:{
            type: Object,
            default: ()=>{}
        }
    },
    components:{
        inputComponent,
        promisedModalTableComponent
    },
    data(){
        return {
            cameras:        [],
            codeScanner:    undefined,
            fallback:       false,
            fallbackValue:  undefined,
            cookieName:     'barcodeCameraId',
            scanSound:      undefined,
            ignoreValue:    false
        }
    },
    async mounted(){
        try{
            this.cameras = await Html5Qrcode.getCameras()
            this.startScanning()
        }
        catch(e){
            let body = "Unrecognised error occured while trying to access camera!"
            if(e.startsWith("NotReadableError") || e.startsWith("NotFoundError")){
                //no cameras available
                body = "Your device seems to have no camera we could use!"
            }
            else if(e.startsWith("NotAllowedError")){
                //no cameras available
                body = "Camera permission is required to scan barcodes!"
            }
            this.fallback = $trans(body)
        }
        if(this.fallback === false && this.playSound){
            this.scanSound = new Howl({src: scanSound})
        }
    },
    methods:{
        async switchManual(){
            await this.codeScanner.stop()
            this.codeScanner = undefined
            this.ignoreValue = true
            this.fallback = true
        },
        switchAuto(){
            if(this.cameras.length === 0){
                return
            }
            this.fallback = false
            setTimeout(()=>this.startScanning(),0)
        },
        startScanning(){
            if(typeof this.codeScanner === 'undefined'){
                this.codeScanner = new Html5Qrcode("reader")
            }
            let cameraSettings = {facingMode: "environment"}
            if(window.hasCookie(this.cookieName)){
                const cameraId = window.getCookie(this.cookieName)
                if(this.cameras.some((camera) => camera.id === cameraId)){
                    cameraSettings = cameraId
                }
                else{
                    window.deleteCookie(this.cookieName)
                }
            }
            this.codeScanner.start(cameraSettings, {
                fps: 10,
                qrbox: {
                    width: 500,
                    height: 500
                }
            }, this.codeScanned)
        },
        codeScanned(text, result){
            if(this.enabled && this.scanValidation(text, result)){
                this.$emit('input', this.scanParser(text))
                if(typeof this.scanSound !== 'undefined'){
                    this.scanSound.play()
                }
            }
        },
        sendFallback(){
            if(this.enabled){
                this.$emit('input', this.fallbackValue)
            }
        },
        async changeCamera(){
            if(typeof this.codeScanner !== 'undefined'){
                const ret = await this.$refs.modal.get()
                if(typeof ret !== 'undefined'){
                    await this.codeScanner.stop()
                    window.setCookie(this.cookieName, ret.id)
                    this.startScanning()
                }
            }
        }
    },
    computed:{
        modalTableSettings(){
            return {
                columns:    [
                    { label: $trans("Name"), field: "label" }
                ],
                data:                       this.cameras,
                sortable:                   false,
                searchable:                 false,
                pagination_informations:    false
            }
        }
    },
    watch:{
        fallbackValue(){
            if(this.ignoreValue){
                this.ignoreValue = false
            }
            else if(window.getIfIsset(()=>this.fallbackInput.type) === 'autocomplete'){
                this.sendFallback()
            }
        }
    }
}
</script>