<template>
    <div class="dynamic-wrapper" ref="wrapper" v-bind:class="dynamicClasses">
        <card-component :disable-resize="resizeDisabled" :is-opened="true" :is-loading="isLoading" :is-closable="true" @close_click="$emit('close_click')" :is-dragable="true" :is-movable="true" :is-resizable="true" v-on:mouse-down="mousedown" v-on:mouse-move="mousemove" v-on:mouse-up="mouseup" v-on:init-resize="resizeInit" v-on:save-resize="signalResized" v-on:mobile-show="mobileShowHandle">
            <template v-slot:header>
                <slot name="header"></slot>
            </template>
            <slot></slot>
        </card-component>
    </div>
</template>
<script>
//todo - scroll
//todo - closing/hiding
module.exports = {
    name:'dynamicCardComponent',
    props:{
        'is-loading':{
            type:Boolean,
            default:false
        },
        'default_settings':{
            type:String,
            default:''
        }
    },
    mounted(){
        try{
            if(this.default_settings.match(/^(true|false),(\d|(0|1)(0|1|2)),(\d|(0|1)(0|1|2)),(\d|(0|1)(0|1|2)),(\d|(0|1)(0|1|2))$/)!==null){
                var default_settings = this.default_settings.split(',');
                if(parseInt(default_settings[1])+parseInt(default_settings[3])>12 || parseInt(default_settings[2])+parseInt(default_settings[4])>12){
                    throw new Error("Default settings incorrect, at least one of the values exceeds grid size!");
                }
                this.visible = default_settings[0]==='true';
                this.x = parseInt(default_settings[1]);
                this.y = parseInt(default_settings[2]);
                this.width = parseInt(default_settings[3]);
                this.height = parseInt(default_settings[4]);
            }
            else if(this.default_settings!==''){
                throw new Error("Default settings incorrect, structure of passed data does not match expected!");
            }
        }
        catch(e){
            console.error(e);
        }
        this.savedConfig = JSON.stringify([this.visible, this.x, this.y, this.width, this.height]);
    },
    methods:{
        mousedown(event, direction){
            if(this.$parent.$options._componentTag != "dynamic-content-container"){
                throw new Error("Resize failed, because parent is not a proper component!");
            }
            this.$parent.$emit('startresize',this);
            this.changeDir = direction;
            switch(this.changeDir){
                case 'left':
                case 'right':
                case 'drag':{
                    this.startX = event.clientX;
                    this.startWidth = parseFloat(window.getComputedStyle(this.$refs.wrapper).width); 
                    this.$refs.wrapper.style.width = this.startWidth+'px';
                    this.startLeft = parseFloat(window.getComputedStyle(this.$refs.wrapper).left);
                    this.$refs.wrapper.style.left = this.startLeft+'px';
                    this.xGrid = this.startWidth/this.width;
                }

                case 'top':
                case 'bottom':
                case 'drag':{
                    this.startY = event.clientY;
                    this.startHeight = parseFloat(window.getComputedStyle(this.$refs.wrapper).height);
                    this.$refs.wrapper.style.height = this.startHeight+'px';
                    this.startTop = parseFloat(window.getComputedStyle(this.$refs.wrapper).top);
                    this.$refs.wrapper.style.top = this.startTop+'px';
                    this.yGrid = this.startHeight/this.height;
                }
            }
        },
        mousemove(event){
            var current_width = parseFloat(this.$refs.wrapper.style.width);
            var current_height = parseFloat(this.$refs.wrapper.style.height);
            var current_left = parseFloat(this.$refs.wrapper.style.left);
            var current_top = parseFloat(this.$refs.wrapper.style.top);
            
            var x_delta = this.startX-event.clientX;
            var y_delta = this.startY-event.clientY;
            switch(this.changeDir){
                case 'left':{
                    var new_width = this.boundValue(this.startWidth+x_delta,this.xGrid,12*this.xGrid);
                    var new_left = this.boundValue(this.startLeft-x_delta,0,11*this.xGrid);

                    if(!(new_left == 0 && new_width>current_width)){
                        this.$refs.wrapper.style.width = new_width+'px';
                    }
                    if(!(new_width == this.xGrid && new_left>current_left)){
                        this.$refs.wrapper.style.left = new_left+'px';
                    }
                } break;
                case 'right':{
                    var new_width = this.boundValue(this.startWidth-x_delta,this.xGrid,(12*this.xGrid)-current_left);

                    this.$refs.wrapper.style.width = new_width+'px';
                } break;

                case 'top':{
                    var new_height = this.boundValue(this.startHeight+y_delta,this.yGrid,(12*this.yGrid));
                    var new_top = this.boundValue(this.startTop-y_delta,0,(11*this.yGrid));

                    this.$refs.wrapper.style.height = new_height+'px';
                    this.$refs.wrapper.style.top = new_top+'px';
                } break;
                case 'bottom':{
                    var new_height = this.boundValue(this.startHeight-y_delta,this.yGrid,(12*this.yGrid)-current_top);

                    this.$refs.wrapper.style.height = new_height+'px';
                } break;

                case 'drag':{
                    var new_left = this.boundValue(this.startLeft-x_delta,0,(12*this.xGrid)-current_width);
                    var new_top = this.boundValue(this.startTop-y_delta,0,(12*this.yGrid)-current_height);

                    this.$refs.wrapper.style.left = new_left+'px';
                    this.$refs.wrapper.style.top = new_top+'px';
                } break;
            }
        },
        mouseup(){
            switch(this.changeDir){
                case 'left':
                case 'right':{
                    this.width = this.boundValue(Math.round(parseFloat(this.$refs.wrapper.style.width)/this.xGrid),1,12);
                }
                case 'left':
                case 'drag':{
                    this.x = this.boundValue(Math.round(parseFloat(this.$refs.wrapper.style.left)/this.xGrid),0,11);
                }

                case 'top':
                case 'bottom':{
                    this.height = this.boundValue(Math.round(parseFloat(this.$refs.wrapper.style.height)/this.yGrid),1,12);
                }
                case 'top':
                case 'drag':{
                    this.y = this.boundValue(Math.round(parseFloat(this.$refs.wrapper.style.top)/this.yGrid),0,11);
                }
            }
            
            this.$refs.wrapper.style.removeProperty('width');
            this.$refs.wrapper.style.removeProperty('height');
            this.$refs.wrapper.style.removeProperty('left');
            this.$refs.wrapper.style.removeProperty('top');
            this.changeDir =    undefined;
            this.startX =       undefined;
            this.startWidth =   undefined;
            this.startLeft =    undefined;
            this.startY =       undefined;
            this.startHeight =  undefined;
            this.xGrid =        undefined;
            this.yGrid =        undefined;
        },
        boundValue(val,min,max){
            return Math.min(Math.max(val,min),max);
        },
        resizeInit(){
            this.$parent.$emit('initresize', this._uid);
        },
        signalResized(){
            var newConfig = JSON.stringify([this.visible, this.x, this.y, this.width, this.height]);
            this.$parent.$emit('resized', this, newConfig !== this.savedConfig);
            this.savedConfig = newConfig;
        },
        disableResize(){
            this.resizeDisabled = true;
        },
        enableResize(){
            this.resizeDisabled = false;
        },
        mobileShowHandle(state){
            this.mobileShow = state;
        }
    },
    computed:{
        dynamicClasses(){
            var ret = ['has-width-'+this.width, 'has-height-'+this.height, 'has-x-offset-'+this.x, 'has-y-offset-'+this.y];
            if(this.mobileShow){
                ret.push('is-mobile-show');
            }
            return ret;
        }
    },
    data(){
        return {
            visible:        true,
            x:              12,
            y:              12,
            width:          12,
            height:         12,

            savedConfig:    [],
            changeDir:      undefined,
            startX:         undefined,
            startWidth:     undefined,
            startLeft:      undefined,
            startY:         undefined,
            startHeight:    undefined,
            xGrid:          undefined,
            yGrid:          undefined,
            resizeDisabled: false,
            mobileShow:     false
        }
    }
}
</script>