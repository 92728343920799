var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { "is-active": _vm.show, "is-up": _vm.isUp },
    },
    [
      _c(
        "div",
        {
          ref: "dropdownElement",
          staticClass: "dropdown-trigger",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleShow.apply(null, arguments)
            },
          },
        },
        [
          _c(
            _vm.triggerComponent,
            {
              ref: "button",
              tag: "component",
              class: {
                button: _vm.triggerComponent === "button",
                "has-icons-right": _vm.showArrow,
                ..._vm.buttonClass,
              },
              attrs: {
                "aria-haspopup": "true",
                "aria-controls": "dropdown-menu",
                disabled: _vm.disabled,
              },
            },
            [
              _vm._t("button"),
              _vm._v(" "),
              _vm.showArrow
                ? _c("icon-component", {
                    staticClass: "is-right",
                    attrs: {
                      icon: _vm.show ? "fas fa-angle-up" : "fas fa-angle-down",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "dropdownMenu",
          staticClass: "dropdown-menu",
          attrs: { role: "menu" },
        },
        [
          _c(
            "div",
            { ref: "content", staticClass: "dropdown-content" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }