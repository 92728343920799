<template>
    <component :is="component" class="button is-auto-progress" :class="{'has-auto-progress-finished': finished && hideFull}" v-bind="$attrs" ref="button" @click="click" @mouseenter="mouseEnter" @mouseleave="mouseLeave" :data-progress="progress">
        <slot></slot>
    </component>
</template>
<script>
module.exports = {
    name:'autoButtonComponent',
    props:{
        component:{
            type:String,
            default:'button'
        },
        autoStart:{
            type:Boolean,
            default:true
        },
        timeout:{
            type:Number,
            default:10000
        },
        interval:{
            type:Number,
            default:100
        },
        pauseOnHover:{
            type:Boolean,
            default:false
        },
        hideFull:{
            type:Boolean,
            default:true
        }
    },
    mounted(){
        if(this.autoStart){
            this.start()
        }
    },
    methods:{
        start(cleanup=true){
            if(cleanup){
                this.progress = 0
                this.started = performance.now()
            }
            else{
                this.started = performance.now() - (this.progress/100)*this.timeout
            }
            this.intervalHandle = setInterval(()=>{
                this.progress = Math.round(((performance.now() - this.started)/this.timeout)*100)
            },this.interval)
        },
        mouseEnter(){
            this.stop(false, !this.pauseOnHover)
        },
        mouseLeave(){
            if(this.pauseOnHover && !this.finished && typeof this.started !== 'undefined'){
                this.start(false)
            }
        },
        stop(click=false, finish=false){
            clearInterval(this.intervalHandle)
            this.intervalHandle = undefined
            if(click || finish){
                this.finished = true
                if(!this.pauseOnHover){
                    this.progress = 100
                }
            }
            if(click){
                this.$nextTick(()=>{
                    this.$refs.button.click()
                })
            }
        },
        click(event){
            this.stop(false, true)
            if(window.isset(()=>this.$listeners.click)){
                setTimeout(this.$listeners.click, 0)
                event.preventDefault()
                return false
            }
        }
    },
    watch:{
        progress(){
            if(this.progress >= 100 && typeof this.intervalHandle !== 'undefined'){
                this.stop(true)
            }
        }
    },
    data(){
        return {
            intervalHandle: undefined,
            started:        undefined,
            progress:       0,
            finished:       false
        }
    }
}
</script>
<style lang="scss">
.button.is-auto-progress{
    &::before{
        content: '';
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: 0;
        background-color: rgba(0, 0, 0, 0.3);
        /* TODO - handle different colors of the button */
        opacity: 1;
        transition: opacity 2s;
        height: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    /* TODO - handle size obtained from the group */
    &.is-small::before{
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    &.is-medium::before{
        height: 6px;
    }

    &.is-large::before{
        height: 8px;
    }

    &.has-progress-large::before{
        height: calc(100% + 2px);
        border-radius: 4px;
    }

    &.has-progress-large.is-small::before{
        border-radius: 2px;
    }

    @for $width from 1 through 100 {
        &[data-progress='#{$width}']::before{
            width: calc(#{percentage($width / 100)} + 2px);
            min-width: 4px;
        }
    }

    &.has-auto-progress-finished::before {
        opacity: 0;
    }
}
</style>
