<template>
    <modal-component :is-card="true" @close="cancel">
        <card-component>
            <h1 v-if="messageHasTitle && options.html" class="title" v-html="messageTitle"></h1>
            <h1 v-if="messageHasTitle && !options.html" class="title">{{messageTitle}}</h1>

            <p v-if="options.html" v-html="messageBody" class="block"></p>
            <p v-else class="block">{{messageBody}}</p>

            <div v-if="mapPosition !== false" :id="_uid+'_map'" class="map-container"></div>

            <input v-if="isHardConfirm || isPrompt" class="input" :type="options.inputType" v-bind="options.inputAttributes" :placeholder="inputPlaceholder" v-model="input" ref="inputElem" :disabled="this.loading" v-on:keyup.enter.stop="submitInput">

            <div class="is-divider"></div>

            <div class="columns" id="alertButtons">
                <div v-if="leftBtnEnabled" class="column is-half">
                    <a v-if="leftButtonLink !== false" :href="leftButtonLink" @click.prevent="clickLeftBtn()" class="button is-fullwidth" v-bind:class="leftButtonClasses" :autofocus="leftBtnFocus" :disabled="leftButtonDisabled">
                        {{leftBtnText}}
                    </a>
                    <button v-else @click="clickLeftBtn()" class="button is-fullwidth" v-bind:class="leftButtonClasses" :autofocus="leftBtnFocus" :disabled="leftButtonDisabled">
                        {{leftBtnText}}
                    </button>
                </div>
                <div v-if="rightBtnEnabled" class="column is-half" v-bind:class="{'is-offset-half':!leftBtnEnabled}">
                    <a v-if="rightButtonLink !== false" :href="rightButtonLink" @click.prevent="clickRightBtn()" class="button is-fullwidth" v-bind:class="rightButtonClasses" :autofocus="rightBtnFocus" :disabled="rightButtonDisabled">
                        {{rightBtnText}}
                    </a>
                    <button v-else @click="clickRightBtn()" class="button is-fullwidth" v-bind:class="rightButtonClasses" :autofocus="rightBtnFocus" :disabled="rightButtonDisabled">
                        {{rightBtnText}}
                    </button>
                </div>
            </div>
        </card-component>
    </modal-component>
</template>

<script>
import DialogMixin from './../components/vuejs-dialog/dist/vuejs-dialog-mixin.min.js';

export default {
    mixins: [DialogMixin],
    mounted(){
        if(isset(()=>this.$refs.inputElem)){
            this.$refs.inputElem.focus();
        }
        if(this.mapPosition !== false){
            this.eventMap = L.map(this._uid+'_map',{
                center:         this.mapPosition,
                zoom:           13,
            });
            L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                minZoom: 4,
                maxZoom: 18
            }).addTo(this.eventMap);
            L.marker(this.mapPosition).addTo(this.eventMap);
            L.control.markerCenter({ markerPos: this.mapPosition }).addTo(this.eventMap);
        }
    },
    methods: {
        submitInput(){
            if((this.options.reverse && !this.leftButtonClasses) || (!this.options.reverse && !this.rightButtonDisabled)){
                this.proceed(this.getDefaultData());
            }
        }
    },
    computed: {
        rightButtonClasses(){
            var ret = [];
            if(this.rightBtnComponent=='ok-btn'){
                if(this.loading){
                    ret.push('is-loading');
                }
                ret.push('is-success');
            }
            else{
                ret.push('is-danger');
            }
            return ret;
        },

        rightButtonDisabled(){
            if(this.isHardConfirm && this.rightBtnComponent=='ok-btn' && !this.loading){
                return this.input.localeCompare(this.options.verification, django.currentLang, {sensitivity: 'base'}) !== 0;
            }
            return this.rightBtnComponent=='cancel-btn' && this.loading;
        },

        rightButtonLink(){
            return (isset(()=>this.options.eventData.button_url) && this.rightBtnComponent=='ok-btn')?this.options.eventData.button_url:false;
        },

        leftButtonClasses(){
            var ret = [];
            if(this.leftBtnComponent=='ok-btn'){
                if(this.loading){
                    ret.push('is-loading');
                }
                ret.push('is-success');
            }
            else{
                ret.push('is-danger');
            }
            return ret;
        },

        leftButtonDisabled(){
            if(this.isHardConfirm && this.leftBtnComponent=='ok-btn' && !this.loading){
                return this.input.localeCompare(this.options.verification, django.currentLang, {sensitivity: 'base'}) !== 0;
            }
            return this.leftBtnComponent=='cancel-btn' && this.loading;
        },

        leftButtonLink(){
            return (isset(()=>this.options.eventData.button_url) && this.leftBtnComponent=='ok-btn')?this.options.eventData.button_url:false;
        },

        inputPlaceholder(){
            if(this.isPrompt && isset(()=>this.options.placeholder)){
                return this.options.placeholder;
            }
            if(this.isHardConfirm){
                return this.options.verification;
            }
            return '';
        },

        mapPosition(){
            if(this.isEvent && isset(()=>this.options.eventData.map_position) && isset(()=>this.options.eventData.map_position.lat) && isset(()=>this.options.eventData.map_position.lng)){
                return this.options.eventData.map_position;
            }
            return false;
        }
    }
};
</script>

<style scoped>
#alertButtons .column {
    padding: 0 1.5rem 0.25rem 1.5rem;
}

.map-container {
    height:20em;
}
</style>