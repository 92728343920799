<template>
    <div class="columns">
        <div class="column is-narrow">
            <input-component :label="$trans('X:')" type="number" step="0.01" v-model="deviceLocation.x" :disabled="inputsDisabled"></input-component>
            <input-component :label="$trans('Y:')" type="number" step="0.01" v-model="deviceLocation.y" :disabled="inputsDisabled"></input-component>
            <input-component :label="$trans('Z:')" type="number" step="0.01" v-model="deviceLocation.z" :disabled="inputsDisabled">
                <button class="button is-danger" @click="deviceLocation.z = null" :disabled="inputsDisabled">
                    <icon-component icon="fas fa-times"></icon-component>
                </button>
            </input-component>
            <div class="buttons">
                <button class="button is-info is-fullwidth" @click="selectOnMap = !selectOnMap">
                    <icon-component icon="fas fa-map"></icon-component>
                    <span>{{$trans('Select location on map')}}</span>
                </button>
                <!-- <button class="button is-danger is-fullwidth" @click="removeLocation">
                    <icon-component icon="fas fa-trash"></icon-component>
                    <span>{{$trans('Remove device location')}}</span>
                </button> -->
                <button class="button is-success is-fullwidth" :class="{'is-loading':loader}" @click="saveLocation" :disabled="noChanges">
                    <icon-component icon="fas fa-save"></icon-component>
                    <span>{{$trans('Save device location')}}</span>
                </button>
            </div>
        </div>
        <div class="column">
            <label class="label">{{$trans('Location preview on map:')}}</label>
            <map-component ref="map" height="400px" :internal="location" :skip-devices="true" @location-inside-click="click"></map-component>
        </div>
    </div>
</template>
<script>
module.exports = {
    name:'deviceLocationComponent',
    props:{
        location:{
            type: Object,
            required: true
        },
        deviceLocation:{
            type: Object,
            required: true
        },
        deviceId:{
            type: Number,
            required: true
        }
    },
    methods:{
        async removeLocation(){
            alert('TODO!')
        },
        async saveLocation(){
            this.loader = true
            let ret = (await axios.post($getUrl('backendDevicesSingleLocation',this.deviceId),this.deviceLocation)).data
            this.snappedDeviceLocation = JSON.stringify({
                location_id:    ret.location_id,
                x:              parseFloat(ret.x),
                y:              parseFloat(ret.y),
                z:              ret.z === null ? null : parseFloat(ret.z),
            })
            this.loader = false
        },
        click(e){
            if(this.selectOnMap){
                this.deviceLocation.x = Math.round(e.x*100)/100
                this.deviceLocation.y = Math.round(e.y*100)/100
                this.selectOnMap = false
            }
        }
    },
    computed:{
        noChanges(){
            return JSON.stringify(this.deviceLocation) === this.snappedDeviceLocation
        },
        inputsDisabled(){
            return this.selectOnMap || this.loader
        }
    },
    mounted(){
        this.snappedDeviceLocation = JSON.stringify(this.deviceLocation)
    },
    watch:{
        deviceLocation:{
            immediate:  true,
            deep: true,
            handler(){
                this.$nextTick(()=>{
                    this.$refs.map.clearLocationDevices(this.location.id)
                    this.$refs.map.drawLocationDevice(this.location.id, this.deviceLocation, false)
                })
            }
        }
    },
    data(){
        return {
            loader:                 false,
            snappedDeviceLocation:  undefined,
            selectOnMap:            false
        }
    }
}
</script>