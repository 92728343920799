//vue
import Vue from "vue";
window.Vue = Vue;
import init_custom_comps from "./components/imports"
init_custom_comps(window.Vue, ['icon-component', 'barcode-scanner-component'])

//our CSS file
import "./vayaplatform.scss";
//fontawesome
import "@fortawesome/fontawesome-free/js/all.min.js";
//leaflet
import L from "leaflet";
import "leaflet.markercluster";
import "./leaflet.fontawesomemarkers";
import "./leaflet.textpath";
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: marker,
    iconRetinaUrl: marker2x,
    shadowUrl: markerShadow,
});
L.Control.MarkerCenter = L.Control.extend({
    options: {
        position: 'topright',
        markerPos: undefined
    },
    onAdd: function(map) {
        const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        const a = L.DomUtil.create('a', undefined, div);
        a.href = "#";
        L.DomEvent.disableClickPropagation(a);
        L.DomEvent.on(a, 'click', L.DomEvent.stop);
        L.DomEvent.on(a, 'click', this._onClick, this);
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.style = "height: 1.4em; vertical-align: -0.4em;"
        svg.setAttribute("viewBox","0 0 384 512");
        const icon = document.createElementNS("http://www.w3.org/2000/svg", "path");
        icon.setAttribute("d", "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z");
        svg.appendChild(icon);
        a.appendChild(svg);
        return div;
    },
    _onClick: function(e) {
        this._map.panTo(this.options.markerPos);
    }
});
L.control.markerCenter = function(opts) {
    return new L.Control.MarkerCenter(opts);
}
window.L = L;
//moment
import moment from "moment";
import "moment/locale/pl";
window.moment = moment;
//isset
window.istype = function(a,t){
    if(!Array.isArray(t))
        t = [t];
    try{
        return t.includes(typeof a());
    }
    catch{
        return false;
    }
};
window.isset = function(a){
    try{
        return typeof a() !== 'undefined';
    }
    catch{
        return false;
    }
};
window.getIfIsset = function(a,b){
    return window.isset(a)?a():b;
}
window.objectsEqual = function(a,b){
    var a_keys = Object.keys(a).sort();
    var b_keys = Object.keys(b).sort();
    if(a_keys.length !== b_keys.length) return false;
    if(JSON.stringify(a_keys) !== JSON.stringify(b_keys)) return false;
    for(var key of a_keys){
        if(a[key] !== b[key]) return false;
    }
    return true;
}
window.waitUntil = function(a, int=100, time=3000){
    return new Promise((resolve, reject)=>{
        if(!a()){
            const interval = setInterval(()=>{
                if(!!a()){
                    clearInterval(interval);
                    clearTimeout(timeout);
                    resolve(true);
                }
            },int);
            const timeout = setTimeout(()=>{
                clearInterval(interval);
                reject("condition was not met before timeout occured!");
            },time);
        }
        else{
            resolve(true);
        }            
    });
}
window.getCookie = function(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
window.safePromise = async function(promise){
    try{
        return (await promise);
    }
    catch(e){}
    return false;
}
Vue.use({
    install: function(Vue, options){
        Vue.prototype.istype = window.istype;
        Vue.prototype.isset = window.isset;
        Vue.prototype.getIfIsset = window.getIfIsset;
        Vue.prototype.objectsEqual = window.objectsEqual;
        Vue.prototype.waitUntil = window.waitUntil;
        Vue.prototype.getCookie = window.getCookie;
    }
});
//debounce
import debounce from "debounce";
window.debounce = debounce;
//axios
import axios from "axios";
axios.interceptors.request.use(async function (config) {
    //config.headers['device-fingerprint'] = await window.getFingerprint();
    if(config.method === 'post' && config.url.startsWith('/')){
        config.headers['X-CSRFToken'] = window.getCookie('csrftoken');
    }
    return config;
});
window.axios = axios;
//chartjs
import {Line} from "vue-chartjs/legacy";
import 'chart.js/auto';
import 'chartjs-adapter-moment';
//vue django translations
window.$trans = function(text, interpolate){
    if(interpolate === null || typeof interpolate === 'undefined')
        return django.gettext(text);
    else
        return django.interpolate(django.gettext(text), interpolate, true);
}
Vue.use({
    install: function(Vue, options){
        Vue.prototype.$trans = window.$trans;
        Vue.prototype.$getUrl = window.$getUrl;
    }
});
//vue async computed
import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);
//vue dialog
import VuejsDialog from "./components/vuejs-dialog/dist/vuejs-dialog.min.js";
import alertDialogComponent from './custom-components/alert-dialog-component.vue'
Vue.use(VuejsDialog,{
    view:           'alert-dialog-component',
    okText:         window.$trans('OK'),
    cancelText:     window.$trans('Cancel'),
    backdropClose:  true,
});
Vue.dialog.registerComponent('alert-dialog-component',alertDialogComponent)
//bulma calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
    screens: {
        tablet: '769px',
        desktop: '1024px',
    },
    datePicker:{
        popover:{
            visibility: 'click',
        }
    },
    theme:{
        color: 'grey',
        contentAccent:{
            light: 'vc-font-bold vc-text-white-200',
        },
        bgLow:{
            light: 'vc-bg-{color}-400 vc-border-2 vc-border-{color}-700',
        },
    }
});
//bulma toast
import * as bulmaToast from "bulma-toast";
window.toast = function(message, type){
    var msg_type;
    switch(type){
        case 'error':   msg_type = 'is-danger';     break;

        case 'warning': msg_type = 'is-warning';    break;

        case 'success': msg_type = 'is-success';    break;

        case 'debug':   msg_type = 'is-primary';    break;

        case 'info':
        default:        msg_type = 'is-info';       break;
    }

    const duration = Math.max(5000,message.split(" ").length*450);
    bulmaToast.toast({
        message:        message,
        type:           msg_type,
        dismissible:    false,
        duration:       duration,
        position:       "bottom-center",
        animate: {
            in:         "bounceInUp",
            out:        "bounceOutDown",
            speed:      " "
        },
        pauseOnHover:   true
    });

    return new Promise((resolve)=>setTimeout(resolve, duration));
};
//addMultipleEventListeners
window.addMultipleEventListeners = function(element, events, callback){
    events = events.split(' ');
    for(var event of events){
        element.addEventListener(event, callback, false);
    }
}
//addDrag
window.addDrag = function(element, callback){
	var isAdvancedUpload = function(){
		var div = document.createElement('div');
		return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
	}();
    if(!isAdvancedUpload) return false;
    var helperDiv = document.createElement('div');
    helperDiv.classList.add('is-drag-helper');

    var textStandard = document.createElement("span");
    textStandard.innerHTML = window.$trans("Drag file over here to upload...");
    textStandard.classList.add('is-standard');
    helperDiv.appendChild(textStandard);

    var textDrop = document.createElement("span");
    textDrop.innerHTML = window.$trans("Drop file to upload...");
    textDrop.classList.add('is-drop');
    helperDiv.appendChild(textDrop);

    element.prepend(helperDiv);
	element.classList.add('is-drag-upload');
	addMultipleEventListeners(element, 'drag dragstart dragend dragover dragenter dragleave drop', function(event){
		event.preventDefault();
		event.stopPropagation();
		return false;
	});
	addMultipleEventListeners(element, 'dragover dragenter', function(event){
		element.classList.add('is-dragged');
	});
	addMultipleEventListeners(element, 'dragleave dragend drop', function(event){
		element.classList.remove('is-dragged');
	});
	addMultipleEventListeners(element, 'drop', function(event){
		callback(event.dataTransfer.files);
	});
	return true;
};
//Tippy
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy, {
    directive:  "has-tooltip",
    arrow:      true,
    delay:      125,
});
Vue.component("tooltip-component", TippyComponent);
//scrollIntoViewIfNeeded
import scrollIntoView from "scroll-into-view-if-needed";
window.scrollIntoView = scrollIntoView;
//service worker
window.showEventDialog = function(data){
    var dialogSettings = {
        customId:   data.id,
        eventData:  data.event_values
    }
    if(isset(()=>data.event_values.button_text)){
        dialogSettings.okText = window.$trans(data.event_values.button_text);
    }
    return Vue.dialog.event({
        title:  data.title,
        body:   window.$trans(data.body, data.body_values !== null?data.body_values:undefined)
    }, dialogSettings);
}
async function handleWorker(){
    const urlBase64ToUint8Array = function(base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
    
        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);
    
        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    try{
        await window.waitUntil(()=>isset(()=>django.userLoggedIn));
        if(django.userLoggedIn && await window.safePromise(window.waitUntil(()=>isset(()=>window.isTemplate)))){
            if('serviceWorker' in navigator){
                await navigator.serviceWorker.register($getUrl('service-worker.js'))
                window.registration = await navigator.serviceWorker.ready;
                const subsriptionOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(await (await fetch($getUrl('vapidPublicKey'))).text())
                };
                var subscription = await window.registration.pushManager.getSubscription();
                const subscriptionState = await window.registration.pushManager.permissionState(subsriptionOptions);
                if(!subscription && subscriptionState !== 'denied'){
                    try{
                        await new Promise((resolve)=>{
                            document.addEventListener('click',(e)=>{
                                e.preventDefault();
                                resolve();
                                return false;
                            },{once:true});
                        });
                        Vue.dialog.alert({
                            title:  window.$trans("Notification request"),
                            body:   window.$trans("In order to display events and alerts notifications on this device we have to ask you for permissions to do so. Apropriate question is displayed in upper-left corner of the screen.")
                        });
                    } catch(e){}
                    const id = Vue.dialog.getLastCustomId();
                    subscription = await window.registration.pushManager.subscribe(subsriptionOptions);
                    Vue.dialog.closeByCustomId(id);
                    /*await axios.post($getUrl('auth:registerBrowser'),{
                        custom_id: await getFingerprint(),
                        params: subscription
                    });*/
                }
                //console.log(JSON.stringify(subscription));
                navigator.serviceWorker.onmessage = events => {
                    events.data.map(async (event, i)=>{
                        switch(event.type){
                            case 'alert_event':{
                                if(window.location.pathname == $getUrl('frontend:my_company:events')){
                                    await window.waitUntil(()=>isset(()=>window.newEventOnFeed));
                                    window.newEventOnFeed(event.data.id);
                                    return;
                                }
                                try{
                                    if(!Vue.dialog.checkCustomId('alert_too_many_events')){
                                        const dialog = showEventDialog(event.data);
                                        if(Vue.dialog.getOpenCount().event > 4 && (i == events.length-1 || events.data.every((ee, ei) => ei<=i || ee.type != 'alert_event'))){
                                            Vue.dialog.closeAllByType('event');
                                            if((await Vue.dialog.alert({
                                                title:  $trans('Multiple events pending'),
                                                body:   $trans("You have currently multiple events pending - in order to see them all go to event feed.")
                                            },{
                                                customId:   'too_many_events',
                                                okText:     $trans('Go to event feed'),
                                                eventData:{
                                                    button_url: $getUrl('frontend:my_company:events')
                                                }
                                            })).success){
                                                window.location.href = $getUrl('frontend:my_company:events');
                                            }
                                        }
                                        else{
                                            await dialog;
                                            await axios.get($getUrl('backendNotificationsRead', { eventId: event.data.id }));
                                            if(dialog._value.success && isset(()=>event.data.event_values.button_url)){
                                                if(!!Vue.dialog.getOpenCount().total){
                                                    //there are some pending event notifications - open in new window
                                                    window.open(event.data.event_values.button_url);
                                                }
                                                else if((window.location.pathname+window.location.search) !== event.data.event_values.button_url){
                                                    window.location.href = event.data.event_values.button_url;
                                                }
                                            }
                                        }
                                    }
                                }
                                catch(error){
                                    if(!(isset(()=>error.graceful) && error.graceful === true)){
                                        console.error(error);
                                    }
                                }
                            } break;
        
                            case 'close_dialog':{
                                Vue.dialog.closeByCustomId('event_'+event.id);
                            } break;
        
                            case 'events_count':{
                                for(const element of document.getElementsByClassName('display-unread-events-count')){
                                    element.innerHTML = event.count;
                                }
                                for(const element of document.getElementsByClassName('display-unread-events-count-hide-if-zero')){
                                    element.innerHTML = event.count;
                                    if(event.count){
                                        element.classList.remove('is-hidden');
                                    }
                                    else{
                                        element.classList.add('is-hidden');
                                    }
                                }
                                window.service_worker_current_events_count = event.count;
                                if(isset(()=>vueInstance.$data.service_worker_current_events_count)){
                                    vueInstance.$data.service_worker_current_events_count = event.count;
                                }
                                if(event.count == 0 && Vue.dialog.checkCustomId('alert_too_many_events')){
                                    Vue.dialog.closeByCustomId('alert_too_many_events');
                                }
                            } break;

                            default:{
                                console.error('got unrecognised message type!');
                                console.warn(event);
                                console.warn('TODO - handle less important messages as toasts?');
                            }
                        }
                    });
                };
                //query for pending messages
                if(window.location.pathname != $getUrl('frontend:my_company:events')){
                    window.registration.active.postMessage({
                        type:       'pending_messages'
                    });
                }
                else{
                    window.registration.active.postMessage({
                        type:       'get_events_count'
                    });
                }
            }
            else if(!document.cookie.includes('incompatible_notified=true')){
                Vue.dialog.alert({
                    title:  window.$trans("Incompatible browser!"),
                    body:   window.$trans("Your browser is not compatible with all features of our system! In order to take full advantage of the system please update your browser!")
                });
                document.cookie = 'incompatible_notified=true; expires=Tue, 19 Jan 2038 03:14:07 GMT';
            }
        }
    }
    catch(error){
        console.error(error);
    }
}
handleWorker();
//fingerprint
/*import Fingerprint2 from "fingerprintjs2";
window.Fingerprint2 = Fingerprint2;
window.cachedFingerprint = undefined;
window.getFingerprint = function(){
    if(typeof window.cachedFingerprint !== 'undefined'){
        return window.cachedFingerprint;
    }
    const ret = new Promise((resolve)=>{
        const obtainFprint = ()=>{
            Fingerprint2.get(function (components) {
                window.cachedFingerprint = Fingerprint2.x64hash128(components.map(function (component) { return component.value }).join(''), 31);
                resolve(window.cachedFingerprint);
            })
        }
        if (window.requestIdleCallback) {
            requestIdleCallback(obtainFprint)
        } else {
            setTimeout(obtainFprint, 500)
        }
    })
    window.cachedFingerprint = ret;
    return ret;
}
*/
//Bowser
import Bowser from "bowser";
window.cachedBrowserData = undefined;
window.browserData = ()=>{
    if(typeof window.cachedBrowserData === 'undefined'){
        window.cachedBrowserData = Bowser.parse(window.navigator.userAgent);
    }
    return window.cachedBrowserData;
}
window.getBrowserName = ()=>`${window.browserData().browser.name} - ${window.browserData().os.name} ${window.browserData().os.versionName}`;
//Jdenticon
import jdenticon from "jdenticon";
window.jdenticon = jdenticon;

import jdenticonComponent from './custom-components/jdenticon-component.vue'
Vue.component('jdenticon-component',jdenticonComponent)
//CSS-Element-Queries
import * as ElementQueries from "css-element-queries";
ElementQueries.ElementQueries.listen();
window.composedPath = (el)=>{
    var path = [];
    while(el){
        path.push(el);
        if (el.tagName === 'HTML') {
            path.push(document);
            path.push(window);
            return path;
        }
        el = el.parentElement;
    }
}
window.getPath = (event)=>{
    return event.path || (event.composedPath && event.composedPath()) || window.composedPath(event.target);
}
//noUiSlider
import noUiSlider from "nouislider";
import 'nouislider/distribute/nouislider.css';
window.noUiSlider = noUiSlider;
import sliderComponent from './custom-components/slider-component.vue'
Vue.component('slider-component',sliderComponent)

import Tagify from '@yaireo/tagify'
window.Tagify = Tagify

import alertComponent from './custom-components/alert-component.vue'
Vue.component('alert-component',alertComponent)

import iconComponent from './custom-components/icon-component.vue'
Vue.component('icon-component',iconComponent)

import dynamicCardComponent from './custom-components/dynamic-card-component.vue'
Vue.component('dynamic-card-component',dynamicCardComponent)

import dynamicContentContainer from './custom-components/dynamic-content-container.vue'
Vue.component('dynamic-content-container',dynamicContentContainer)

import dashboardComponent from './custom-components/dashboard-component.vue'
Vue.component('dashboard-component',dashboardComponent)

import breadcrumbComponent from './custom-components/breadcrumb-component.vue'
Vue.component('breadcrumb-component',breadcrumbComponent)

import calendarComponent from './custom-components/calendar-component.vue'
Vue.component('calendar-component',calendarComponent)

import calendarButtonComponent from './custom-components/calendar-button-component.vue'
Vue.component('calendar-button-component',calendarButtonComponent)

import statusComponent from './custom-components/status-component.vue'
Vue.component('status-component',statusComponent)

import measurementsChart from './custom-components/measurements-chart.vue'
Vue.component('measurements-chart',measurementsChart)

import measurementsComponent from './custom-components/measurements-component.vue'
Vue.component('measurements-component',measurementsComponent)

import mapComponentBare from './custom-components/map-component-bare.vue'
Vue.component('map-component-bare',mapComponentBare)
import mapComponent from './custom-components/map-component.vue'
Vue.component('map-component',mapComponent)

import batteryIndicatorComponent from './custom-components/battery-indicator-component.vue'
Vue.component('battery-indicator-component',batteryIndicatorComponent)

import configurationComponent from './custom-components/configuration-component.vue'
Vue.component('configuration-component',configurationComponent)

import eventDetails from './custom-components/event-details.vue'
Vue.component('event-details',eventDetails)

Vue.component('line-chart',Line)

import notificationComponent from './custom-components/notification-component.vue'
Vue.component('notification-component',notificationComponent)

import placeholderedInputComponent from './custom-components/placeholdered-input-component.vue'
Vue.component('placeholdered-input-component',placeholderedInputComponent)

import deviceLocationComponent from './custom-components/device-location-component.vue'
Vue.component('device-location-component',deviceLocationComponent)