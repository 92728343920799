<template>
    <div class="dynamic-cards-container" v-bind:class="select" @mousemove="mousemove" @mouseup="mouseup">
        <slot></slot>
    </div>
</template>
<script>
module.exports = {
    name:'dynamicContentContainer',
    mounted(){
        this.$on('initresize',(uid)=>{
            for(var element of this.$children){
                if(element.$options._componentTag=="dynamic-card-component" && element._uid !== uid){
                    element.disableResize();
                }
            }
        });
        this.$on('startresize',(child)=>{
            this.resizeInProgress = child;
        });
        this.$on('resized',(child, change)=>{
            if(this.$parent.$options._componentTag == "dashboard-component" && change){
                this.$parent.childResized(child);
            }
            for(var element of this.$children){
                if(element.$options._componentTag=="dynamic-card-component"){
                    element.enableResize();
                }
            }
        });
    },
    methods:{
        mousemove(event){
            if(this.resizeInProgress !== false){
                this.resizeInProgress.mousemove(event);
            }
        },
        mouseup(){
            if(this.resizeInProgress !== false){
                this.resizeInProgress.mouseup();
                this.resizeInProgress = false;
            }
        }
    },
    data(){
        return {
            resizeInProgress:   false
        }
    },
    computed:{
        select(){
            return this.resizeInProgress?'no-select':'';
        }
    }
}
</script>