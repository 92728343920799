<template>
    <div class="card" id="keyboard-card" :class="{'is-active': isActive}" ref="keyboardCard">
        <div class="card-content" style="width: 75%; margin: auto;">
            <div class="columns" v-for="(keysRow, i) in currentKeys" :key="i">
                <div class="column p-1" v-for="(key, j) in keysRow" :key="j">
                    <button class="button is-fullwidth is-dark" :class="keysClasses[i][j]" @click="click(keys[i][j])" v-html="key"></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'VirtualKeyboard',
    props:{
        keys:{
            type: Array,
            default: ()=>[
                [(s)=>s?'~':'`', (s)=>s?'!':'1', (s)=>s?'@':'2', (s)=>s?'#':'3', (s)=>s?'$':'4', (s)=>s?'%':'5', (s)=>s?'^':'6', (s)=>s?'&':'7', (s)=>s?'*':'8', (s)=>s?'(':'9', (s)=>s?')':'0', (s)=>s?'_':'-', (s)=>s?'+':'=',{t:'<i class="fas fa-long-arrow-alt-left"></i>', f:'removeChar'}],
                ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', (s)=>s?'{':'[', (s)=>s?'}':']', (s)=>s?'|':'\\'],
                [{t:'CAPS', f:'toggleCaps', c:{'is-link':'hasCaps'}}, 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', (s)=>s?':':';', (s)=>s?'"':'\''],
                [{t:'SHIFT', f:'toggleShift', c:{'is-link':'hasShift'}}, 'z', 'x', 'c', 'v', 'b', 'n', 'm', (s)=>s?'<':',', (s)=>s?'>':'.', (s)=>s?'?':'/', {t:'SHIFT', f:'toggleShift', c:{'is-link':'hasShift'}}],
                [{t:' ', c:{'is-wide':true}}]
            ]
        },
        input:{
            default: undefined,
            required: true
        }
    },
    data(){
        return {
            hasShift: false,
            hasCaps: false,
            isActive: false
        }
    },
    computed:{
        currentKeys(){
            return this.keys.map((row)=>row.map((key)=>{
                if(typeof key === 'string'){
                    if(key.length === 1 && key[0] >= 'a' && key[0] <= 'z'){
                        if(this.hasShift ^ this.hasCaps){
                            return key.toUpperCase()
                        }
                        else{
                            return key
                        }
                    }
                    return key
                }
                if(typeof key === 'function'){
                    return key(this.hasShift, this.hasCaps)
                }
                if(window.isset(()=>key.t)){
                    if(typeof key.t === 'string'){
                        return key.t
                    }
                    if(typeof key.t === 'function'){
                        return key.t(this.hasShift, this.hasCaps)
                    }
                }
            }))
        },
        keysClasses(){
            const vueThis = this
            return this.keys.map((row)=>row.map((key)=>{
                if(window.isset(()=>key.c)){
                    return Object.entries(key.c).map((e)=>{
                        if(e[1] === true){
                            return e[0]
                        }
                        if(window.isset(()=>vueThis[e[1]]) && !!vueThis[e[1]]){
                            return e[0]
                        }
                        return undefined
                    }).filter((e)=> typeof e !== 'undefined')
                }
                return undefined
            }))
        }
    },
    methods:{
        click(key){
            if(window.isset(()=>key.f) && window.isset(()=>this[key.f])){
                this[key.f]()
            }
            else{
                //handle reg. keys
                let val = undefined
                if(typeof key === 'function'){
                    val = key(this.hasShift, this.hasCaps)
                }
                else if(window.isset(()=>key.t)){
                    val = key.t
                }
                else{
                    if(this.hasShift ^ this.hasCaps){
                        val = key.toUpperCase()
                    }
                    else{
                        val = key
                    }
                }
                this.input.value += val
                this.input.dispatchEvent(new Event('input'))
                if(this.hasShift){
                    this.hasShift = false
                }
            }
            this.input.focus()
        },
        toggleCaps(){
            this.hasCaps = !this.hasCaps
        },
        toggleShift(){
            this.hasShift = !this.hasShift
        },
        removeChar(){
            this.input.value = this.input.value.slice(0,-1)
            this.input.dispatchEvent(new Event('input'))
        },
        focus(){
            this.isActive = true
        },
        blur(e, force=false){
            if(force || !(e.toElement === this.input || e.path.includes(this.$refs.keyboardCard))){
                this.isActive = false
            }
        }
    },
    watch:{
        input:{
            immediate: true,
            handler(n, o){
                if(typeof o !== 'undefined'){
                    //detach watches
                    o.removeEventListener('focus', this.focus)
                    document.documentElement.removeEventListener('click', this.blur)
                    this.blur(undefined, true)
                }
                if(typeof n !== 'undefined'){
                    //attach watches
                    n.addEventListener('focus', this.focus)
                    if(document.activeElement === n){
                        this.focus()
                    }
                    setTimeout(()=>document.documentElement.addEventListener('click', this.blur),0)
                    //this.$nextTick(()=>document.documentElement.addEventListener('click', this.blur))
                }
            }
        }
    }
}
</script>
<style>
.card#keyboard-card {
    position: fixed;
    bottom: -300px;
    left: 0;
    width: 100%;
    z-index: 2000;
    transition: bottom 0.5s;
}

.card.is-active#keyboard-card {
    bottom: 0px;
}
</style>