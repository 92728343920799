const IS_DEBUG = process.env.NODE_ENV !== 'production'

import AsyncComputed from "vue-async-computed"
import axios from 'axios'
import debounce from 'debounce'

export function istype(a,t){
    if(!Array.isArray(t))
        t = [t];
    try{
        return t.includes(typeof a());
    }
    catch{
        return false;
    }
}

export function isset(a){
    try{
        return typeof a() !== 'undefined';
    }
    catch{
        return false;
    }
}

export function getIfIsset(a,b){
    return isset(a)?a():b;
}

export function objectsEqual(a,b){
    var a_keys = Object.keys(a).sort();
    var b_keys = Object.keys(b).sort();
    if(a_keys.length !== b_keys.length) return false;
    if(JSON.stringify(a_keys) !== JSON.stringify(b_keys)) return false;
    for(var key of a_keys){
        if(a[key] !== b[key]) return false;
    }
    return true;
}

export function waitUntil(a, int=100, time=3000){
    return new Promise((resolve, reject)=>{
        if(!a()){
            const interval = setInterval(()=>{
                if(a()){
                    clearInterval(interval);
                    clearTimeout(timeout);
                    resolve(true);
                }
            },int);
            const timeout = setTimeout(()=>{
                clearInterval(interval);
                reject("condition was not met before timeout occured!");
            },time);
        }
        else{
            resolve(true);
        }            
    });
}

export function delay(ms){
    return new Promise((resolve)=>{
        setTimeout(resolve, ms)
    })
}

export function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function setCookie(name, value, exdays=365){
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function hasCookie(name){
    return getCookie(name) !== null;
}

export function deleteCookie(name){
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function composedPath(el){
    var path = [];
    while(el){
        path.push(el);
        if (el.tagName === 'HTML') {
            path.push(document);
            path.push(window);
            return path;
        }
        el = el.parentElement;
    }
}

export function getPath(event){
    return event.path || (event.composedPath && event.composedPath()) || composedPath(event.target);
}

export function addMultipleEventListeners(element, events, callback){
    events = events.split(' ')
    for(const event of events){
        element.addEventListener(event, callback, false)
    }
}

export function addDrag(element, callback){
    var isAdvancedUpload = function(){
        var div = document.createElement('div')
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    }()
    if(!isAdvancedUpload){
        console.warn("Your browser does not support drag and drop functionality")
        return false
    }
    var helperDiv = document.createElement('div')
    helperDiv.classList.add('is-drag-helper')

    var textStandard = document.createElement("span")
    textStandard.innerHTML = $trans("Przeciągnij tutaj plik...")
    textStandard.classList.add('is-standard')
    helperDiv.appendChild(textStandard)

    var textDrop = document.createElement("span")
    textDrop.innerHTML = $trans("Upuść plik...")
    textDrop.classList.add('is-drop')
    helperDiv.appendChild(textDrop)

    element.prepend(helperDiv)
    element.classList.add('is-drag-upload')
    addMultipleEventListeners(element, 'drag dragstart dragend dragover dragenter dragleave drop', function(event){
        event.preventDefault()
        event.stopPropagation()
        return false
    })
    addMultipleEventListeners(element, 'dragover dragenter', function(){
        element.classList.add('is-dragged')
    })
    addMultipleEventListeners(element, 'dragleave dragend drop', function(){
        element.classList.remove('is-dragged')
    })
    addMultipleEventListeners(element, 'drop', function(event){
        callback(event.dataTransfer.files)
    })
    return true
}

export function readTextFile(file){
    return new Promise((resolve,reject)=>{
        var reader = new FileReader()
        reader.onload = function(e){
            resolve(e.target.result)
        };
        reader.onabort = reject
        reader.onerror = reject
        reader.readAsText(file)
    })
}

export function readBinaryFile(file){
    return new Promise((resolve,reject)=>{
        var reader = new FileReader()
        reader.onload = function(e){
            resolve(e.target.result)
        };
        reader.onabort = reject
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
    })
}

export function $trans(text, interpolate){
    const hasInterpolation = !(interpolate === null || typeof interpolate === 'undefined')
    if(typeof django === 'undefined'){
        if(hasInterpolation){
            let ret = text
            if(Array.isArray(interpolate)){
                for(const entry of interpolate){
                    ret = ret.replace('%s', entry)
                }
            }
            else{
                for(const entry of Object.entries(interpolate)){
                    ret = ret.replace(`%(${entry[0]})s`, entry[1])
                }
            }
            return ret
        }
        else{
            return text
        }
    }
    else{
        const ret = django.gettext(text)
        if(hasInterpolation){
            return django.interpolate(ret, interpolate, !Array.isArray(interpolate))
        }
        else{
            return ret
        }
    }
}

export function import_tools(Vue=getIfIsset(()=>window.Vue, null)){
    window.istype = istype
    window.isset = isset
    window.getIfIsset = getIfIsset
    window.objectsEqual = objectsEqual
    window.waitUntil = waitUntil
    window.delay = delay
    window.getCookie = getCookie
    window.setCookie = setCookie
    window.hasCookie = hasCookie
    window.deleteCookie = deleteCookie
    window.composedPath = composedPath
    window.getPath = getPath
    
    window.addMultipleEventListeners = addMultipleEventListeners
    window.addDrag = addDrag
    window.readTextFile = readTextFile
    window.readBinaryFile = readBinaryFile

    window.$trans = $trans

    window.axios = axios
    window.debounce = debounce
    
    if(Vue !== null){
        Vue.use({
            install: function(Vue){
                Vue.prototype.istype = window.istype
                Vue.prototype.isset = window.isset
                Vue.prototype.getIfIsset = window.getIfIsset
                Vue.prototype.objectsEqual = window.objectsEqual
                Vue.prototype.waitUntil = window.waitUntil
                Vue.prototype.delay = window.delay
                Vue.prototype.$trans = window.$trans
                Vue.prototype.axios = window.axios
            }
        });
        Vue.use(AsyncComputed)
    }
    else if(IS_DEBUG){
        console.warn("Vue instance not available!")
    }
}