<script>
export default {
    functional: true,
    props: {
        show:{
            type: Boolean,
            default: true
        }  
    },
    render(h, ctx) {
        const children = ctx.children.filter(vnode => vnode.tag);
        if(children.length !== 1)
            throw new Error('This component accepts only one root node in its slot');
        if(ctx.props.show)
            return children[0];
        return children[0].children;
    }
}
</script>