<template>
    <nav class="breadcrumb" aria-label="breadcrumb">
        <ul>
            <li v-for="(link, i) in links" :key="i" :class="(i==links.length-1)?'is-active':''">
                <a :href="link.link" :aria-current="(i==links.length-1)?'page':false">
                    <template v-if="typeof link.icon !== 'undefined'">
                        <icon-component :icon="link.icon"/>
                        <span>
                            {{link.name}}
                        </span>
                    </template>
                    <template v-else-if="typeof link.deviceType !== 'undefined'">
                        <icon-component :device-type="link.deviceType"/>
                        <span>
                            {{link.name}}
                        </span>
                    </template>
                    <template v-else>
                        {{link.name}}
                    </template>
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
module.exports = {
    name:'breadcrumbComponent',
    props:{
        links:{
            type:Array,
            default:()=>[
                {
                    "name":"units",
                    "icon":"fas fa-home",
                    "link":"/units"
                }
            ]
        }
    },
    data() {
        return {
            
        }
    }
}
</script>
<style>
    .added-li {
        margin-left:0.5rem;
    }
</style>