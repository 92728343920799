<template>
    <conditional-wrapper :show="wrap">
        <span ref="iconWrapper" class="icon" v-bind:class="sizeClass">
            <i v-if="!slash" :class="sizedIcon"></i>
            <span v-else class="fa-stack" :class="slashSize">
                <i :class="sizedIcon"></i>
                <i v-if="slash" class="fas fa-slash fa-stack-1x fa-inverse" data-fa-transform="down-2.5"></i>
                <i v-if="slash" class="fas fa-slash fa-stack-1x"></i>
            </span>
        </span>
    </conditional-wrapper>
</template>
<script>
import conditionalWrapper from './conditional-wrapper.vue';

export default {
    name:'iconComponent',
    props:{
        icon:{
            type: [String, Boolean],
            default: 'fas fa-question'
        },
        size:{
            type: String,
            default: 'S'
        },
        wrap:{
            type: Boolean,
            default: true,
        },
        slash:{
            type: Boolean,
            default: false,
        }
    },
    components:{conditionalWrapper},
    watch:{
        sizedIcon(nVal,oVal){
            if(this.$refs.iconWrapper.querySelector("[data-fa-i2svg]") != null){
                this.$refs.iconWrapper.querySelector("[data-fa-i2svg]").classList.remove(...oVal.split(' '));
                this.$refs.iconWrapper.querySelector("[data-fa-i2svg]").classList.add(...nVal.split(' '));
            }
        }
    },
    computed:{
        sizedIcon(){
            var ret = undefined;
            if(this.icon === true)
                ret = 'fas fa-check-square';
            else if(this.icon === false)
                ret = 'far fa-square';
            else
                ret = this.icon.replace(/(\s|^)fa-(xs|sm|lg|\d+x)(\s|$)/,' ');
            if(this.slash){
                ret += ' fa-stack-1x';
            }
            else{
                switch(this.size){
                    case 'L':   ret += ' fa-3x'; break;
                    case 'M':   ret += ' fa-2x'; break;
                }
            }            
            return ret;
        },
        slashSize(){
            switch(this.size){
                case 'L':   return 'fa-3x';
                case 'M':   return 'fa-2x';
                case 'S':
                default:    return '';
            }
        },
        sizeClass(){
            switch(this.size){
                case 'L':   return 'is-large';
                case 'M':   return 'is-medium';
                case 'S':
                default:    return '';
            }
        }
    }
}
</script>