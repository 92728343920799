<template>
    <transition v-on:leave="leave">
        <div class="modal" v-bind:class="{'is-active':isVisible}">
            <div ref="background" class="modal-background animated fadeIn" v-on:click="backClick"></div>
            <div ref="content" class="animated bounceIn" :class="contentClassParsed" style="overflow: unset; animation-duration:800ms;" :style="contentStyle">
                <slot v-if="!isCard"></slot>
                <template v-else>
                    <header class="modal-card-head">
                        <slot name="header">
                            <p class="modal-card-title">
                                {{title}}
                            </p>
                        </slot>
                    </header>
                    <section class="modal-card-body">
                        <slot></slot>
                    </section>
                    <footer class="modal-card-foot">
                        <slot name="footer"></slot>
                    </footer>
                </template>
            </div>
            <button class="modal-close is-large" aria-label="close" v-on:click="buttonClick" v-if="isClosable"></button>
        </div>
    </transition>
</template>
<script>
export default {
    name:'modalComponent',
    props:{
        'is-visible':{
            type:       Boolean,
            default:    true
        },
        'is-closable':{
            type:       Boolean,
            default:    true
        },
        'content-style':{
            type:       String,
            default:    ''
        },
        'content-class':{
            type:       String,
            default:    ''
        },
        'is-card':{
            type:       Boolean,
            default:    false
        },
        'title':{
            type:       String,
            default:    ''
        }
    },
    methods:{
        buttonClick: function(){
            if(this.isClosable){
                this.$emit('close','buttonClose');
            }
        },
        backClick: function(){
            if(this.isClosable){
                this.$emit('close','backClose');
            }
        },
        escapeCloser: function(evt){
            if(this.isClosable){
                if(("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) || evt.keyCode === 27){
                    this.$emit('close','escapeClose');
                }
            }
        },
        leave(el,done){
            this.$refs.content.classList.replace('bounceIn','bounceOut');
            this.$refs.background.classList.replace('fadeIn','fadeOut');
            setTimeout(()=>{
                done();
            },1000);
        },
    },
    watch:{
        isVisible:{
            immediate: true,
            handler: function() {
                if(this.isClosable){
                    if(this.isVisible){
                        document.addEventListener("keydown",this.escapeCloser,true);
                    }
                    else{
                        document.removeEventListener("keydown",this.escapeCloser,true);
                    }
                }
            }
        }
    },
    computed:{
        contentClassParsed(){
            return `${this.isCard ? 'modal-card' : 'modal-content'} ${this.contentClass}`
        }
    }
}
</script>
<style scoped>
.modal-content.bounceIn{
    animation-delay: 200ms;
}
.modal-background.fadeOut{
    animation-delay: 200ms;
    animation-duration:800ms;
}
.modal-content.is-fullwidth{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
</style>