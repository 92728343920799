<template>
    <promised-modal-component :modal-settings="modalSettings" :reject-behavior="rejectBehavior" ref="modal">
        <template v-slot:header="{select, close}">
            <slot name="header" v-bind:select="select" v-bind:close="close"></slot>
        </template>
        <template v-slot:default="{select, close}">
            <table-component v-bind="mergedTableSettings" @row-click="select"></table-component>
            <slot v-bind:select="select" v-bind:close="close"></slot>
        </template>
        <template v-slot:footer="{select, close}">
            <slot name="footer" v-bind:select="select" v-bind:close="close"></slot>
        </template>
    </promised-modal-component>
</template>
<script>
import promisedModalComponent from './promised-modal-component.vue'
import tableComponent from './table-component.vue'

export default {
    name:'promisedModalTableComponent',
    props:{
        modalSettings:{
            type: Object,
            default: ()=>({})
        },
        tableSettings:{
            type: Object,
            default: ()=>({})
        },
        rejectBehavior:{
            default: undefined,
            validator: (value)=>{
                return typeof value !== 'string' || value === 'reject'
            }
        }
    },
    components:{
        promisedModalComponent,
        tableComponent
    },
    computed:{
        mergedModalSettings(){
            return {...this.modalSettings, ...this.componentModalSettings}
        },
        mergedTableSettings(){
            return {...this.tableSettings, ...this.componentTableSettings}
        }
    },
    data(){
        return {
            componentModalSettings: {

            },
            componentTableSettings: {
                url_params:         false,
                clickable_rows:     true
            }
        }
    },
    methods:{
        //exposed methods
        get(){
            return this.$refs.modal.get()
        },
        close(){
            return this.$refs.modal.close()
        }
    }
}
</script>