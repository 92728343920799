<template>
    <div>
        <label class="label" v-if="label">{{label}}</label>
        <textarea ref="textarea" v-model="value"></textarea>
    </div>
</template>
<script>
module.exports = {
    name:'placeholderedInputComponent',
    props:{
        label:{
            type: String
        },
        value:{
            type: String
        },
        allowed_tags:{
            type: Array
        }
    },
    mounted(){
        this.input = new Tagify(this.$refs.textarea, {
            mode: 'mix',
            mixMode:{
                insertAfterTag: ""
            },
            duplicates: true,
            pattern: '{',
            mixTagsInterpolator: ['{','}'],
            whitelist: this.allowed_tags,
            enforceWhitelist: true,
            originalInputValueFormat: (tag)=>tag.value,
            editTags: false,

            tagTextProp: 'text',
            dropdown : {
                enabled: 1,
                position: 'text',
                mapValueTo: 'text',
                highlightFirst: true
            }
        })

        this.$refs.textarea.addEventListener('change', ()=>{
            this.$emit('input', this.$refs.textarea.value)
        })
    },
    methods:{
    },
    data(){
        return {
            input: undefined
        }
    },
    watch:{
        value(){
            if(this.value !== this.$refs.textarea.value){
                this.input.loadOriginalValues(this.value)
            }
        }
    }
}
</script>