<template>
    <svg :width="size" :height="size" :data-jdenticon-value="value" class="is-jdenticon"></svg>
</template>
<script>
module.exports = {
    name:'jdenticonComponent',
    props:{
        value:{
            type: String,
            default: ''
        },
        size:{
            type: Number,
            default: 50
        }
    },
    mounted(){
        this.$nextTick(()=>jdenticon.update(this.$el));
    },
    watch:{
        value(){
            this.$nextTick(()=>jdenticon.update(this.$el));
        }
    }
}
</script>