var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { on: { leave: _vm.leave } }, [
    _c("div", { staticClass: "modal", class: { "is-active": _vm.isVisible } }, [
      _c("div", {
        ref: "background",
        staticClass: "modal-background animated fadeIn",
        on: { click: _vm.backClick },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "animated bounceIn",
          class: _vm.contentClassParsed,
          staticStyle: { overflow: "unset", "animation-duration": "800ms" },
          style: _vm.contentStyle,
        },
        [
          !_vm.isCard
            ? _vm._t("default")
            : [
                _c(
                  "header",
                  { staticClass: "modal-card-head" },
                  [
                    _vm._t("header", function () {
                      return [
                        _c("p", { staticClass: "modal-card-title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.title) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "section",
                  { staticClass: "modal-card-body" },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "footer",
                  { staticClass: "modal-card-foot" },
                  [_vm._t("footer")],
                  2
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.isClosable
        ? _c("button", {
            staticClass: "modal-close is-large",
            attrs: { "aria-label": "close" },
            on: { click: _vm.buttonClick },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }