var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _vm.fallback === false
        ? [
            _c("promised-modal-table-component", {
              ref: "modal",
              attrs: {
                tableSettings: _vm.modalTableSettings,
                modalSettings: {
                  title: _vm.$trans("Select camera to use"),
                  "is-card": true,
                },
              },
            }),
            _vm._v(" "),
            _vm.label
              ? _c("label", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.label)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { attrs: { id: "reader" } }),
            _vm._v(" "),
            _vm.cameras.length > 1
              ? _c(
                  "button",
                  {
                    staticClass: "button is-white is-small",
                    staticStyle: {
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                    },
                    on: { click: _vm.changeCamera },
                  },
                  [
                    _c("icon-component", { attrs: { icon: "fas fa-camera" } }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$trans("Different camera"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-white is-small",
                staticStyle: {
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                },
                on: { click: _vm.switchManual },
              },
              [
                _c("icon-component", { attrs: { icon: "fas fa-pencil-alt" } }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$trans("Manual input")))]),
              ],
              1
            ),
          ]
        : [
            _vm.cameras.length
              ? _c(
                  "button",
                  {
                    staticClass: "button is-primary is-outlined",
                    on: { click: _vm.switchAuto },
                  },
                  [
                    _c("icon-component", { attrs: { icon: "fas fa-camera" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$trans("Scan code")))]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.fallback !== true
              ? _c("p", { staticClass: "help is-danger" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.fallback) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "input-component",
              _vm._b(
                {
                  attrs: { label: _vm.$trans(_vm.fallbackLabel) },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.sendFallback.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.fallbackValue,
                    callback: function ($$v) {
                      _vm.fallbackValue = $$v
                    },
                    expression: "fallbackValue",
                  },
                },
                "input-component",
                _vm.fallbackInput,
                false
              ),
              [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary is-outlined",
                    attrs: { disabled: !_vm.enabled },
                    on: { click: _vm.sendFallback },
                  },
                  [
                    _c("icon-component", { attrs: { icon: "fas fa-save" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$trans("Save")))]),
                  ],
                  1
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }