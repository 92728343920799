var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "battery-indicator-wrapper" },
    [
      _c("icon-component", {
        attrs: { icon: "fas fa-battery-empty", size: "L" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "battery-indicator", style: _vm.batteryStyle }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }