<template>
    <div style="position:relative;">
        <line-chart :chart-data="chartData" :chartOptions="options" :styles="styles" :width="null" :height="null"></line-chart>
    </div>
</template>
<script>
module.exports = {
    name:'measurementsChart',
    props:{
        measurements:{
            type: Array,
            default: ()=>[]
        },
        colorSet:{
            type: Array,
            //color set from Google Charts
            default: ()=>['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC']
        },
        width:{
            type: [String, Number],
            default: undefined
        },
        height:{
            type: [String, Number],
            default: undefined
        },
        xMin:{
            type: Number,
            default: undefined
        },
        xMax:{
            type: Number,
            default: undefined
        }
    },
    data(){
        return {
            timeFormat:     'MM/DD/YYYY HH:mm',
            uoms:[]
        };
    },
    computed:{
        chartData(){
            const ret = [];
            this.uoms = [];
            for(const i in this.measurements){
                if(isset(()=>this.measurements[i].aggregation) && this.measurements[i].aggregation !== null){
                    switch(this.measurements[i].aggregation){
                        case 'avg_min_max':{
                            if(!this.measurements[i].measurements.every((e)=>
                                isset(()=>e.value_numeric_min) && e.value_numeric_min !== null && isset(()=>e.value_numeric_avg) && e.value_numeric_avg !== null && isset(()=>e.value_numeric_max) && e.value_numeric_max !== null
                            )){
                                console.error("avg_min_max aggregation was selected, but data is not complete for such aggregation");
                            }
                            else{
                                const COLOR_SPREAD_FACTOR = 25;
                                const measurement_min = {
                                    label:                  $trans(this.measurements[i].uom.descr) + ' - ' + $trans("minimum"),
                                    borderColor:            this.LightenDarkenColor(this.colorSet[i],COLOR_SPREAD_FACTOR),
                                    backgroundColor:        this.LightenDarkenColor(this.colorSet[i],COLOR_SPREAD_FACTOR)+'a0',
                                    fill:                   '+1',
                                    cubicInterpolationMode: 'monotone',
                                    pointRadius:            0,
                                    data:                   [],
                                    borderDash: [5, 5]
                                };
                                const measurement_avg = {
                                    label:                  $trans(this.measurements[i].uom.descr) + ' - ' + $trans("average"),
                                    borderColor:            this.colorSet[i],
                                    backgroundColor:        this.colorSet[i],
                                    fill:                   false,
                                    cubicInterpolationMode: 'monotone',
                                    pointRadius:            0,
                                    data:                   []
                                };
                                const measurement_max = {
                                    label:                  $trans(this.measurements[i].uom.descr) + ' - ' + $trans("maximum"),
                                    borderColor:            this.LightenDarkenColor(this.colorSet[i],-COLOR_SPREAD_FACTOR),
                                    backgroundColor:        this.LightenDarkenColor(this.colorSet[i],-COLOR_SPREAD_FACTOR)+'a0',
                                    fill:                   '-1',
                                    cubicInterpolationMode: 'monotone',
                                    pointRadius:            0,
                                    data:                   [],
                                    borderDash: [5, 5]
                                };
                                for(const point of this.measurements[i].measurements){
                                    const timestamp = point.timestamp*1000;
                                    measurement_min.data.push({
                                        x: timestamp,
                                        y: point.value_numeric_min
                                    });
                                    measurement_avg.data.push({
                                        x: timestamp,
                                        y: point.value_numeric_avg
                                    });
                                    measurement_max.data.push({
                                        x: timestamp,
                                        y: point.value_numeric_max
                                    });
                                }
                                ret.push(measurement_min, measurement_avg, measurement_max);
                                this.uoms.push(this.measurements[i].uom.name + ' - ' + $trans("minimum"), this.measurements[i].uom.name + ' - ' + $trans("average"), this.measurements[i].uom.name + ' - ' + $trans("maximum"));
                            }
                        } break;
                        default:
                            console.error("Unrecognised aggregation found >>"+this.measurements[i].aggregation+"<<");
                            break;
                    }
                }
                else{
                    const series = ((isset(()=>this.measurements[i].measurements[0]) && this.measurements[i].measurements[0] !== null?Object.keys(this.measurements[i].measurements[0]):[]).filter((s)=>this.measurements[i].measurements[0][s] !== null)).filter((e)=>e!=='timestamp' && e!=='heading');
                    if(!series.length || !this.measurements[i].measurements.every((e)=>
                        series.every(s=>isset(()=>e[s]) && e[s] !== null)
                    )){
                        console.error("data is not complete for dataset!");
                    }
                    else{
                        if(series.length === 1){
                            const measurement = {
                                label:                  $trans(this.measurements[i].uom.descr),
                                borderColor:            this.colorSet[i],
                                backgroundColor:        this.colorSet[i],
                                fill:                   false,
                                cubicInterpolationMode: 'monotone',
                                pointRadius:            0,
                                data:                   []
                            };
                            for(const point of this.measurements[i].measurements){
                                measurement.data.push({
                                    x: point.timestamp*1000,
                                    y: point[series[0]]
                                });
                            }
                            ret.push(measurement);
                            this.uoms.push(this.measurements[i].uom.name);
                        }
                        else{
                            for(const serie of series){
                                const measurement = {
                                    label:                  $trans(this.measurements[i].uom.descr) + ' - ' + serie,
                                    borderColor:            this.colorSet[i],
                                    backgroundColor:        this.colorSet[i],
                                    fill:                   false,
                                    cubicInterpolationMode: 'monotone',
                                    data:                   []
                                };
                                for(const point of this.measurements[i].measurements){
                                    measurement.data.push({
                                        x: point.timestamp*1000,
                                        y: point[serie]
                                    });
                                }
                                ret.push(measurement);
                                this.uoms.push(this.measurements[i].uom.name + ' - ' + serie);
                            }
                        }
                    }
                }
            }
            return {
                datasets: ret
            };
        },
        options(){
            return {
                responsive: true,
                maintainAspectRatio: false,
                stacked: false,
                plugins:{
                    legend:{
                        display: false
                    },
                },
                scales: {
                    x:{
						type: 'time',
						time: {
							parsing:false
                        },
                        min:isset(()=>this.xMin)?this.xMin*1000:undefined,
                        max:isset(()=>this.xMax)?this.xMax*1000:undefined,
                        ticks: {
                            major: {
								enabled: true
                            },
                        },
						title: {
							display: true,
							text: $trans('Date')
                        }
					},
                    y: {
                        beginAtZero : true,
						title: {
                            display: true,
							text: $trans(this.measurements[0].uom.descr)+' ['+this.measurements[0].uom.name+']'
                        },

					}
                }
            }
        },
        styles(){
            const ret = {};
            if(isset(()=>this.width)){
                ret.width = `${this.width}px`;
                ret.position = 'relative';
            }
            if(isset(()=>this.height)){
                ret.height = `${this.height}px`;
                ret.position = 'relative';
            }
            return ret;
        }
    },
    methods:{
        LightenDarkenColor(col, amt) {
            var usePound = false;
        
            if (col[0] == "#") {
                col = col.slice(1);
                usePound = true;
            }
        
            var num = parseInt(col,16);
        
            var r = (num >> 16) + amt;
            if (r > 255) r = 255;
            else if  (r < 0) r = 0;
        
            var b = ((num >> 8) & 0x00FF) + amt;
            if (b > 255) b = 255;
            else if  (b < 0) b = 0;
        
            var g = (num & 0x0000FF) + amt;
            if (g > 255) g = 255;
            else if (g < 0) g = 0;
        
            return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
        }
    }
}
</script>