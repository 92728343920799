<template>
    <div>
        <modal-component v-if="Array.isArray(sheetSelectOptions) && sheetSelectOptions.length > 1" @close="clearImport">
            <div class="box">
                <h2 class="subtitle">{{$trans('Wybierz arkusz do importu:')}}</h2>
                <input-component type="select" :options="sheetSelectOptions" v-model="sheetSelect"></input-component>
                <hr>
                <div class="columns">
                    <div class="column">
                        <button class="button is-fullwidth is-danger" @click="clearImport">
                            {{$trans('Anuluj')}}
                        </button>
                    </div>
                    <div class="column">
                        <button class="button is-fullwidth is-success" @click="readWorkbookData(sheetSelect)">
                            {{$trans('Wybierz')}}
                        </button>
                    </div>
                </div>
            </div>
        </modal-component>

        <article class="message mb-0">
            <div class="message-header is-clickable" @click="accordion = 0">
                <p>{{$trans('Import pliku')}}</p>
            </div>
            <div class="message-body is-collapsible" v-show="accordion === 0">
                <div class="message-body-content">
                    <label v-if="(typeof label) !== 'undefined'" class="label">{{label}}</label>
                    <input-component type="file" :drag="true" @files="fileUpload" accept=".xls,.xlsx,.xlsm,.xlsb,.ods,.csv,.txt"></input-component>
                </div>
            </div>
        </article>

        <article class="message mb-0">
            <div class="message-header" style="border-radius: 0px; border-top: 2px solid black;">
                <p>{{$trans('Wybór danych')}}</p>
            </div>
            <div class="message-body is-collapsible" v-show="accordion === 1">
                <div class="message-body-content">
                    <template v-if="data.length">
                        <input-component type="checkbox" :label="$trans('Importowane dane mają nagłówek (pomiń pierwszy wiersz)')" v-model="importDataHasHeader"></input-component>
                        <table class="table is-fullwidth mb-4">
                            <thead>
                                <tr v-if="(typeof dataHeader) !== 'undefined'">
                                    <th v-for="(cell, i) of dataHeader" :key="i" :class="{'has-background-grey':selectedColumns[i] === null}">
                                        {{cell}}
                                    </th>
                                </tr>
                                <tr>
                                    <th v-for="i in columnsCount" :key="i-1" :class="{'has-background-grey':selectedColumns[i-1] === null}">
                                        <input-component type="select" :options="possibleColumns" v-model="selectedColumns[i-1]"></input-component>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, i) of displayData" :key="i">
                                    <td v-for="(cell, j) of row" :key="j" :class="{'has-background-grey':selectedColumns[j] === null}">
                                        {{cell}}
                                    </td>
                                </tr>
                                <tr v-if="displayData.length !== rowsCount">
                                    <td :colspan="columnsCount">
                                        <icon-component icon="fas fa-ellipsis-h"></icon-component><br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="notification is-info p-2 pl-4 mb-2">
                            {{$trans('Importowanych rekordów: %(records_count)s',{records_count:rowsCount})}}
                        </div>
                        <div class="notification is-danger p-2 pl-4 mb-2" v-if="missingRequiredColumns.length">
                            {{$trans('Brakujące wymagane kolumny: %(missing_columns)s',{missing_columns: missingRequiredColumns.map((column)=>`"${getIfIsset(()=>column.text, column)}"`).join(', ')})}}
                        </div>
                        <button class="button is-success is-fullwidth" :disabled="missingRequiredColumns.length !== 0 || rowsCount === 0" @click="exportData">
                            <icon-component icon="fas fa-save"></icon-component>
                            <span>{{$trans('Zatwierdź import')}}</span>
                        </button>
                    </template>
                    <div v-else class="notification is-warning">
                        {{$trans('Brak danych do wyboru! Dokonaj najpierw importu pliku w polu powyżej.')}}
                    </div>
                </div>
            </div>
        </article>
        <article class="message mb-5">
            <div class="message-header" style="border-radius: 0px; border-top: 2px solid black;">
                <p>{{$trans('Podsumowanie importu')}}</p>
            </div>
            <div class="message-body is-collapsible" v-show="accordion === 2">
                <div class="message-body-content">
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr>
                                <th class="is-narrow">{{$trans('Zaimportowanych rekordów:')}}</th>
                                <td colspan="2">{{rowsCount}}</td>
                            </tr>
                            <tr>
                                <th class="is-narrow" :rowspan="selectedGivenColumns.length">{{$trans('Przykładowy rekord:')}}</th>
                                <template v-if="rowsCount && selectedGivenColumns.length">
                                    <td class="is-narrow">
                                        <label class="label">{{selectedGivenColumns[0].text}}:</label>
                                    </td>
                                    <td>
                                        <h2 class="subtitle">{{exampleRecord[selectedGivenColumns[0].value]}}</h2>
                                    </td>
                                </template>
                            </tr>
                            <template v-if="rowsCount">
                                <tr v-for="(column, i) of selectedGivenColumns.slice(1)" :key="i">
                                    <td class="is-narrow">
                                        <label class="label">{{column.text}}:</label>
                                    </td>
                                    <td>
                                        <h2 class="subtitle">{{exampleRecord[column.value]}}</h2>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </article>
    </div>
</template>
<script>
import inputComponent from './input-component.vue'
export default {
  components: { inputComponent },
    name:'csvImportComponent',
    props:{
        label:{
            type: String,
            default: undefined
        },
        requiredColumns:{
            type: Array,
            required: true,
            validator: (arr)=>arr.every((el)=>typeof el === 'string' || (typeof el === 'object' && isset(()=>el.text) && isset(()=>el.value)))
        },
        optionalColumns:{
            type: Array,
            default: ()=>[],
            validator: (arr)=>arr.every((el)=>typeof el === 'string' || (typeof el === 'object' && isset(()=>el.text) && isset(()=>el.value)))
        }
    },
    data(){
        return {
            accordion:          0,
            workbook:           undefined,
            data:               [],
            sheetSelect:        undefined,
            sheetSelectOptions: [],
            selectedColumns:    [],
            importDataHasHeader:false
        }
    },
    computed:{
        dataHeader(){
            if(this.importDataHasHeader){
                return this.data[0]
            }
            return undefined
        },
        exampleRecord(){
            let row = []
            if(this.data.length){
                if(this.importDataHasHeader){
                    if(this.data.length >= 2){
                        row = this.data.slice(1, 2)[0]
                    }
                }
                else{
                    row = this.data.slice(0, 1)[0]
                }
            }
            return row.reduce((a, e, i) => {
                if(this.selectedColumns[i] !== null){
                    a[this.selectedColumns[i]] = e
                }
                return a
            },{})
        },
        displayData(){
            if(this.importDataHasHeader){
                return this.data.slice(1, 10)
            }
            return this.data.slice(0, 9)
        },
        columnsCount(){
            if(this.data.length){
                return this.data[0].length
            }
            return 0
        },
        rowsCount(){
            if(this.importDataHasHeader){
                return Math.max(this.data.length-1, 0)
            }
            return this.data.length
        },
        givenColumns(){
            return [].concat(this.requiredColumns.slice(0).map(this.columnsMap), this.optionalColumns.slice(0).map(this.columnsMap))
        },
        selectedGivenColumns(){
            return this.givenColumns.filter((column)=>this.selectedColumns.includes(column.value))
        },
        possibleColumns(){
            return [{
                text:   $trans('POMIŃ'),
                value:  null
            }].concat(this.givenColumns)
        },
        missingRequiredColumns(){
            return this.requiredColumns.slice(0).filter((column)=>!this.selectedColumns.includes(getIfIsset(()=>column.value, column)))
        },
        usedColumns(){
            return this.selectedColumns.map((column, index)=>({
                column,
                index
            })).filter((column)=>column.column !== null).map((column)=>{
                const colDef = this.possibleColumns.find((e)=>e.value === column.column)
                return {
                    text:   typeof colDef !== 'undefined' ? colDef.text : undefined,
                    index:  column.index
                }
            })
        }
    },
    methods:{
        columnsMap(column){
            if(typeof column === 'string'){
                return {
                    text:       column,
                    value:      column,
                    disabled:   this.selectedColumns.includes(column)
                }
            }
            else{
                column.disabled = this.selectedColumns.includes(column.value)
                return column
            }
        },
        async fileUpload(files){
            this.workbook = XLSX.read(await window.readBinaryFile(files[0]))
            if(this.workbook.SheetNames.length > 1){
                this.sheetSelectOptions = this.workbook.SheetNames
            }
            else{
                this.readWorkbookData(this.workbook.SheetNames[0])
            }
            
        },
        clearImport(){
            this.workbook = undefined
            this.sheetSelectOptions = []
        },
        readWorkbookData(sheetName){
            if(!isset(()=>this.workbook.Sheets[sheetName])){
                return
            }
            this.data = XLSX.utils.sheet_to_json(this.workbook.Sheets[sheetName],{header:1})
            this.clearImport()
            this.accordion = 1
        },
        exportData(){
            this.$emit('data', this.data.filter((e,i) => !(i === 0 && this.importDataHasHeader)).map((row) => row.reduce((a, e, i) => {
                if(this.selectedColumns[i] !== null){
                    a[this.selectedColumns[i]] = e
                }
                return a
            },{})))
            this.$emit('columns', this.selectedGivenColumns.map((column)=>{
                let copy = JSON.parse(JSON.stringify(column))
                delete copy.disabled
                return copy
            }))
            this.accordion = 2
        }
    },
    watch:{
        importDataHasHeader(){
            if(this.importDataHasHeader && this.selectedGivenColumns.length === 0){
                //no colums selected yet, try to match
                this.dataHeader.forEach((e, i)=>{
                    const thisColumn = e.toLowerCase()
                    let column = this.givenColumns.find((column)=>thisColumn.includes(column.text.toLowerCase()) || thisColumn.includes(column.value.toLowerCase()))
                    if(typeof column !== 'undefined'){
                        Vue.set(this.selectedColumns, i, column.value)
                    }
                })
            }
        }
    }
}
</script>