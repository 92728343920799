var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-date-picker",
    _vm._b(
      {
        attrs: {
          mode: _vm.isRange ? "range" : "single",
          locale: _vm.locale,
          "min-date": _vm.allowedDateRange[0],
          "max-date": _vm.allowedDateRange[1],
        },
        on: {
          popoverWillShow: function ($event) {
            return _vm.$emit("popoverWillShow", $event)
          },
          popoverWillHide: function ($event) {
            return _vm.$emit("popoverWillHide", $event)
          },
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      },
      "v-date-picker",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }