<template>
    <div>
        <modal-component :is-visible="currentDefinition !== false" @close="currentDefinition = false">
            <card-component v-if="currentDefinition !== false" :is-loading="loader">
                <input-component :label="$trans('Name')" v-model="currentDefinition.name" required></input-component>
                <label class="label">{{$trans('Conditions to fulfill')}}</label>
                <template v-for="i of currentDefinition.body.conditions.map((e, i)=>i)">
                    <div :key="`condition_divider_${i}`" class="is-divider mt-4 mb-4"></div>
                    <input-component :key="`condition_uom_${i}`" :label="$trans('Type of measurement')" v-model="currentDefinition.body.conditions[i].uom" :options="uoms" type="select" @input="uomChanged(i)"></input-component>
                    <input-component :key="`condition_operator_${i}`" :label="$trans('Operator')" v-model="currentDefinition.body.conditions[i].operator" :options="operators[i]" type="select"></input-component>
                    <template v-if="currentDefinition.body.conditions[i].uom === 'location'">
                        <label :key="`condition_label_${i}`" class="label">{{$trans('Value')}}</label>
                        <map-component-bare :key="`condition_map_${i}`" ref="map" height="400px" :drawable="true"></map-component-bare>
                    </template>
                    <input-component :key="`condition_value_${i}`" v-else :label="$trans('Value')" v-model="currentDefinition.body.conditions[i].value" type="number" required></input-component>
                    <button :key="`condition_delete_button_${i}`" class="button is-danger is-right" v-if="currentDefinition.body.conditions.length !== 1" @click="removeCondition(i)">
                        <icon-component icon="fas fa-trash-alt"></icon-component>
                        <span>{{$trans('Remove this condition')}}</span>
                    </button>
                </template>
                <button class="button is-success is-light is-fullwidth mt-4" @click="addCondition">
                    <icon-component icon="fas fa-plus"></icon-component>
                    <span>{{$trans('Add condition')}}</span>
                </button>
                <label class="label">{{$trans('Actions to perform')}}</label>
                <template v-for="i of currentDefinition.body.actions.map((e, i)=>i)">
                    <div :key="`action_divider_${i}`" class="is-divider mt-4 mb-4"></div>
                    <input-component :key="`action_type_${i}`" :label="$trans('Type of notification')" v-model="currentDefinition.body.actions[i].type" :options="[{text: $trans('Email'), value: 'email'}]" type="select" disabled></input-component>
                    <input-component :key="`action_address_${i}`" :label="$trans('Address')" v-model="currentDefinition.body.actions[i].address" type="email" required></input-component>
                    <placeholdered-input-component :key="`action_message_${i}`" :label="$trans('Message body')" v-model="currentDefinition.body.actions[i].message" :allowed_tags="messageAvailableTemplates" required></placeholdered-input-component>
                    <button :key="`action_delete_button_${i}`" class="button is-danger is-right" v-if="currentDefinition.body.actions.length !== 1" @click="removeAction(i)">
                        <icon-component icon="fas fa-trash-alt"></icon-component>
                        <span>{{$trans('Remove this action')}}</span>
                    </button>
                </template>
                <button class="button is-success is-light is-fullwidth mt-4" @click="addAction">
                    <icon-component icon="fas fa-plus"></icon-component>
                    <span>{{$trans('Add action')}}</span>
                </button>
                <div class="is-divider mt-4 mb-4"></div>
                <button class="button is-success is-fullwidth" @click="save">
                    <icon-component icon="fas fa-save"></icon-component>
                    <span>{{$trans('Save')}}</span>
                </button>
            </card-component>
        </modal-component>
        <table-component v-bind="tableSettings" ref="table">
            <template v-slot:button="{row}">
                <button class="button is-link" @click="openDefinition(row)">
                    <icon-component icon="fas fa-clipboard-list"></icon-component>
                    <span>{{$trans('Details')}}</span>
                </button>
            </template>
        </table-component>
        <button class="button is-success is-light" @click="newDefinition">
            <icon-component icon="fas fa-plus"></icon-component>
            <span>{{$trans('Add new notification')}}</span>
        </button>
    </div>
</template>
<script>
module.exports = {
    name:'notificationComponent',
    props:{
        device:{
            type: Number
        },
        shipment:{
            type: Number
        },
        url_params:{
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            currentDefinition: false,
            loader: false,
            uoms:[
                {text: $trans("Location"), value: "location"},
                {text: $trans("Temperature"), value: "temperature"},
                {text: $trans("Humidity"), value: "humidity"},
                {text: $trans("Battery level"), value: "battery"}
            ]
        }
    },
    computed:{
        tableSettings(){
            return {
                columns:[
                    { label: $trans("Name"), field: "name"},
                    { class: 'is-narrow', slotName: "button"},
                ],
                url: typeof this.shipment !== 'undefined' ? $getUrl('backendShipmentsSingleNotifications', {shipmentId: this.shipment}) : $getUrl('backendDevicesSingleNotifications', {deviceId: this.device}),
                url_params: this.url_params
            }
        },
        operators(){
            return window.getIfIsset(()=>this.currentDefinition.body.conditions, []).map((condition)=>{
                if(condition.uom === 'location'){
                    return [
                        {text: $trans("Inside"), value: "inside"},
                        {text: $trans("Outside"), value: "outside"},
                    ]
                }
                return [
                    {text: $trans("Equal"), value: "eq"},
                    {text: $trans("Not equal"), value: "neq"},
                    {text: $trans("Less than"), value: "lt"},
                    {text: $trans("Less than or equal"), value: "lte"},
                    {text: $trans("Greater than"), value: "gt"},
                    {text: $trans("Greater than or equal"), value: "gte"},
                ]
            })
        },
        messageAvailableTemplates(){
            return window.getIfIsset(()=>this.currentDefinition.body.conditions, []).reduce((a, e)=>{
                if(e.uom !== 'location' && !a.includes(e.uom)){
                    a.push(e.uom)
                }
                return a
            },['timestamp', 'device_uid'])
        }
    },
    methods:{
        openDefinition(definition){
            this.currentDefinition = JSON.parse(JSON.stringify(definition))
        },
        newDefinition(){
            this.currentDefinition = {
                body: {
                    conditions: [],
                    actions: []
                }
            }
            if(typeof this.shipment !== 'undefined'){
                this.currentDefinition.shipment_id = this.shipment
            }
            if(typeof this.device !== 'undefined'){
                this.currentDefinition.device_id = this.device
            }
            this.addCondition()
            this.addAction()
        },
        uomChanged(i){
            if(this.currentDefinition.body.conditions[i].uom === 'location'){
                if(typeof this.currentDefinition.body.conditions[i].value !== 'object'){
                    this.currentDefinition.body.conditions[i].value = ""
                }
                else{
                    console.log("load geojson")
                }
                this.$nextTick(()=>{
                    this.$refs.map[i].map.pm.addControls({
                        position: 'topleft',
                        drawMarker: false,
                        drawCircleMarker: false,
                        drawPolyline: false,
                        drawText: false,
                        cutPolygon: false
                    })
                    if(Object.keys(this.currentDefinition.body.conditions[i].value).length){
                        this.$refs.map[i].clearGeoJSONs()
                        this.$refs.map[i].map.fitBounds(this.$refs.map[i].drawGeoJSON(undefined, this.currentDefinition.body.conditions[i].value).getBounds().pad(0.1),{
                            maxZoom: 16
                        })
                    }
                })
            }
            if(this.currentDefinition.body.conditions[i].uom !== 'location' && !(typeof this.currentDefinition.body.conditions[i].value === 'number' || typeof this.currentDefinition.body.conditions[i].value === 'string')){
                this.currentDefinition.body.conditions[i].value = ""
            }
        },
        async save(){
            this.loader = true
            try{
                for(const i in this.currentDefinition.body.conditions){
                    if(this.currentDefinition.body.conditions[i].uom === 'location'){
                        const value = this.$refs.map[i].getGeoJSON()
                        if(value === false){
                            window.toast($trans("Geofencing definition must consist of exactly one shape!"),'error')
                            this.loader = false
                            return
                        }
                        this.currentDefinition.body.conditions[i].value = value.geometry
                    }
                }
                const ret = (await axios.post($getUrl('backendNotificationsDefinition'),this.currentDefinition)).data
                this.$refs.table.forceUpdate()
                this.currentDefinition = false
            }
            catch(e){
                console.error(e)
                window.toast($trans("Error occured while saving notification definition! Please try again."),'error')
            }
            this.loader = false
        },
        removeCondition(i){
            this.currentDefinition.body.conditions.splice(i, 1)
        },
        addCondition(){
            this.currentDefinition.body.conditions.push({
                uom: 'temperature',
                operator: 'gt',
                value: '15'
            })
        },
        removeAction(i){
            this.currentDefinition.body.actions.splice(i, 1)
        },
        addAction(){
            this.currentDefinition.body.actions.push({
                type: 'email',
                address: '',
                message: ''
            })
        }
    },
    watch:{
        currentDefinition(n, o){
            if(o === false){
                for(const i in this.currentDefinition.body.conditions){
                    this.uomChanged(i)
                }
            }
        }
    }
}
</script>