var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loader-wrapper", class: _vm.show ? "is-active" : "" },
    [_c("div", { staticClass: "loader is-loading" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }