var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalShown
    ? _c(
        "modal-component",
        _vm._b(
          { on: { close: _vm.close } },
          "modal-component",
          _vm.mergedModalSettings,
          false
        ),
        [
          _vm._t("header", null, { close: _vm.close, select: _vm.select }),
          _vm._v(" "),
          _vm._t("default", null, { close: _vm.close, select: _vm.select }),
          _vm._v(" "),
          _vm._t("footer", null, { close: _vm.close, select: _vm.select }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }