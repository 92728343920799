var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showTooltip
    ? _c(
        "span",
        {
          directives: [{ name: "has-tooltip", rawName: "v-has-tooltip" }],
          staticClass: "tag",
          class: _vm.statusClass,
          attrs: { content: _vm.translatedTooltip },
        },
        [_vm._v("\n    " + _vm._s(_vm.translatedStatus) + "\n")]
      )
    : _c("span", { staticClass: "tag", class: _vm.statusClass }, [
        _vm._v("\n    " + _vm._s(_vm.translatedStatus) + "\n"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }