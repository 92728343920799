var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.component,
    _vm._b(
      {
        ref: "button",
        tag: "component",
        staticClass: "button is-auto-progress",
        class: { "has-auto-progress-finished": _vm.finished && _vm.hideFull },
        attrs: { "data-progress": _vm.progress },
        on: {
          click: _vm.click,
          mouseenter: _vm.mouseEnter,
          mouseleave: _vm.mouseLeave,
        },
      },
      "component",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }