<template>
    <v-date-picker :mode="isRange?'range':'single'" v-model="date" :locale="locale" @popoverWillShow="$emit('popoverWillShow',$event)" @popoverWillHide="$emit('popoverWillHide',$event)" v-bind="$attrs" :min-date="allowedDateRange[0]" :max-date="allowedDateRange[1]">
        <slot></slot>
    </v-date-picker>
</template>
<script>
module.exports = {
    name:'calendarComponent',
    props:{
        isRange:{
            type:Boolean,
            default:false
        },
        value:{
            type:[Date,Array,Object,null],
            default:null,
            validator:(v)=>{
                return v == null || v instanceof Date || (Array.isArray(v) && v.reduce((o,e)=>o && e instanceof Date),true) || (isset(()=>v.start) && v.start instanceof Date && isset(()=>v.end) && v.end instanceof Date);
            }
        },
        allowedDateRange:{
            type: Array,
            default: ()=>[undefined, undefined]
        }
    },
    mounted(){
        var getLocale = ()=>{
            if(isset(()=>django.currentLang))
                this.locale = django.currentLang;
            else
                setTimeout(getLocale,50);
        };
        getLocale();
    },
    data() {
        return {
            date:   null,
            locale: 'en-gb',
        }
    },
    watch:{
        date(){
            this.$emit('input', this.date);
        },
        value:{
            deep:true,
            immediate:true,
            handler(){
                this.date = this.value;
            }
        }
    },
}
</script>