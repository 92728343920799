var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "modal-component",
        {
          attrs: { "is-visible": _vm.currentDefinition !== false },
          on: {
            close: function ($event) {
              _vm.currentDefinition = false
            },
          },
        },
        [
          _vm.currentDefinition !== false
            ? _c(
                "card-component",
                { attrs: { "is-loading": _vm.loader } },
                [
                  _c("input-component", {
                    attrs: { label: _vm.$trans("Name"), required: "" },
                    model: {
                      value: _vm.currentDefinition.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentDefinition, "name", $$v)
                      },
                      expression: "currentDefinition.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$trans("Conditions to fulfill"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.currentDefinition.body.conditions.map((e, i) => i),
                    function (i) {
                      return [
                        _c("div", {
                          key: `condition_divider_${i}`,
                          staticClass: "is-divider mt-4 mb-4",
                        }),
                        _vm._v(" "),
                        _c("input-component", {
                          key: `condition_uom_${i}`,
                          attrs: {
                            label: _vm.$trans("Type of measurement"),
                            options: _vm.uoms,
                            type: "select",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.uomChanged(i)
                            },
                          },
                          model: {
                            value: _vm.currentDefinition.body.conditions[i].uom,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentDefinition.body.conditions[i],
                                "uom",
                                $$v
                              )
                            },
                            expression:
                              "currentDefinition.body.conditions[i].uom",
                          },
                        }),
                        _vm._v(" "),
                        _c("input-component", {
                          key: `condition_operator_${i}`,
                          attrs: {
                            label: _vm.$trans("Operator"),
                            options: _vm.operators[i],
                            type: "select",
                          },
                          model: {
                            value:
                              _vm.currentDefinition.body.conditions[i].operator,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentDefinition.body.conditions[i],
                                "operator",
                                $$v
                              )
                            },
                            expression:
                              "currentDefinition.body.conditions[i].operator",
                          },
                        }),
                        _vm._v(" "),
                        _vm.currentDefinition.body.conditions[i].uom ===
                        "location"
                          ? [
                              _c(
                                "label",
                                {
                                  key: `condition_label_${i}`,
                                  staticClass: "label",
                                },
                                [_vm._v(_vm._s(_vm.$trans("Value")))]
                              ),
                              _vm._v(" "),
                              _c("map-component-bare", {
                                key: `condition_map_${i}`,
                                ref: "map",
                                refInFor: true,
                                attrs: { height: "400px", drawable: true },
                              }),
                            ]
                          : _c("input-component", {
                              key: `condition_value_${i}`,
                              attrs: {
                                label: _vm.$trans("Value"),
                                type: "number",
                                required: "",
                              },
                              model: {
                                value:
                                  _vm.currentDefinition.body.conditions[i]
                                    .value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentDefinition.body.conditions[i],
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentDefinition.body.conditions[i].value",
                              },
                            }),
                        _vm._v(" "),
                        _vm.currentDefinition.body.conditions.length !== 1
                          ? _c(
                              "button",
                              {
                                key: `condition_delete_button_${i}`,
                                staticClass: "button is-danger is-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeCondition(i)
                                  },
                                },
                              },
                              [
                                _c("icon-component", {
                                  attrs: { icon: "fas fa-trash-alt" },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$trans("Remove this condition"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "button is-success is-light is-fullwidth mt-4",
                      on: { click: _vm.addCondition },
                    },
                    [
                      _c("icon-component", { attrs: { icon: "fas fa-plus" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$trans("Add condition")))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("label", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$trans("Actions to perform"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.currentDefinition.body.actions.map((e, i) => i),
                    function (i) {
                      return [
                        _c("div", {
                          key: `action_divider_${i}`,
                          staticClass: "is-divider mt-4 mb-4",
                        }),
                        _vm._v(" "),
                        _c("input-component", {
                          key: `action_type_${i}`,
                          attrs: {
                            label: _vm.$trans("Type of notification"),
                            options: [
                              { text: _vm.$trans("Email"), value: "email" },
                            ],
                            type: "select",
                            disabled: "",
                          },
                          model: {
                            value: _vm.currentDefinition.body.actions[i].type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentDefinition.body.actions[i],
                                "type",
                                $$v
                              )
                            },
                            expression:
                              "currentDefinition.body.actions[i].type",
                          },
                        }),
                        _vm._v(" "),
                        _c("input-component", {
                          key: `action_address_${i}`,
                          attrs: {
                            label: _vm.$trans("Address"),
                            type: "email",
                            required: "",
                          },
                          model: {
                            value:
                              _vm.currentDefinition.body.actions[i].address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentDefinition.body.actions[i],
                                "address",
                                $$v
                              )
                            },
                            expression:
                              "currentDefinition.body.actions[i].address",
                          },
                        }),
                        _vm._v(" "),
                        _c("placeholdered-input-component", {
                          key: `action_message_${i}`,
                          attrs: {
                            label: _vm.$trans("Message body"),
                            allowed_tags: _vm.messageAvailableTemplates,
                            required: "",
                          },
                          model: {
                            value:
                              _vm.currentDefinition.body.actions[i].message,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentDefinition.body.actions[i],
                                "message",
                                $$v
                              )
                            },
                            expression:
                              "currentDefinition.body.actions[i].message",
                          },
                        }),
                        _vm._v(" "),
                        _vm.currentDefinition.body.actions.length !== 1
                          ? _c(
                              "button",
                              {
                                key: `action_delete_button_${i}`,
                                staticClass: "button is-danger is-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAction(i)
                                  },
                                },
                              },
                              [
                                _c("icon-component", {
                                  attrs: { icon: "fas fa-trash-alt" },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$trans("Remove this action"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "button is-success is-light is-fullwidth mt-4",
                      on: { click: _vm.addAction },
                    },
                    [
                      _c("icon-component", { attrs: { icon: "fas fa-plus" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$trans("Add action")))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "is-divider mt-4 mb-4" }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button is-success is-fullwidth",
                      on: { click: _vm.save },
                    },
                    [
                      _c("icon-component", { attrs: { icon: "fas fa-save" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$trans("Save")))]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "table-component",
        _vm._b(
          {
            ref: "table",
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function ({ row }) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "button is-link",
                        on: {
                          click: function ($event) {
                            return _vm.openDefinition(row)
                          },
                        },
                      },
                      [
                        _c("icon-component", {
                          attrs: { icon: "fas fa-clipboard-list" },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$trans("Details")))]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          "table-component",
          _vm.tableSettings,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-success is-light",
          on: { click: _vm.newDefinition },
        },
        [
          _c("icon-component", { attrs: { icon: "fas fa-plus" } }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$trans("Add new notification")))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }