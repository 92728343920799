/*
  Leaflet.AwesomeMarkers, a plugin that adds colorful iconic markers for Leaflet, based on the Font Awesome icons
  (c) 2012-2013, Lennard Voogdt

  http://leafletjs.com
  https://github.com/lvoogdt
*/

/*global L*/

(function (window, document, undefined) {
    "use strict";
    /*
     * Leaflet.AwesomeMarkers assumes that you have already included the Leaflet library.
     */

    L.AwesomeMarkers = {};

    L.AwesomeMarkers.version = '2.0.1';

    L.AwesomeMarkers.Icon = L.Icon.extend({
        options: {
            iconSize: [36, 36],
            iconAnchor:   [18, 18],
            popupAnchor: [1, -32],
            shadowAnchor: [10, 12],
            shadowSize: [36, 16],
            className: 'is-marker',
            prefix: 'fa',
            spinClass: 'fa-spin',
            extraClasses: '',
            icon: 'truck',
            markerColor: 'default',
            defaultMarkerColor: 'default',
            pulse: false,
            href: undefined
        },

        initialize: function (options) {
            options = L.Util.setOptions(this, options);
        },

        createIcon: function () {
            var a = document.createElement('a'),
                options = this.options;
            
            if (typeof options.href !== 'undefined') {
                a.href = options.href;
            }

            if (options.icon) {
                a.innerHTML = this._createInner();
            }

            if (options.bgPos) {
                a.style.backgroundPosition =
                    (-options.bgPos.x) + 'px ' + (-options.bgPos.y) + 'px';
            }

            this._setIconStyles(a, 'icon-' + (options.markerColor=='default'?options.defaultMarkerColor:options.markerColor));
            a.className += ' is-marker-' + (options.markerColor=='default'?options.defaultMarkerColor:options.markerColor);
            if(options.pulse)
                a.className += ' pulse';
            return a;
        },

        _createInner: function() {
            var iconClass, iconSpinClass = "", options = this.options;

            if(options.icon.slice(0,options.prefix.length+1) === options.prefix + "-") {
                iconClass = options.icon;
            } else {
                iconClass = options.prefix + "-" + options.icon;
            }

            if(options.spin && typeof options.spinClass === "string") {
                iconSpinClass = options.spinClass;
            }

            return "<i class='" + options.extraClasses + " " + options.prefix + " " + iconClass + " " + iconSpinClass + "'></i>";
        },

        _setIconStyles: function (img, name) {
            var options = this.options,
                size = L.point(options[name === 'shadow' ? 'shadowSize' : 'iconSize']),
                anchor;

            if (name === 'shadow') {
                anchor = L.point(options.shadowAnchor || options.iconAnchor);
            } else {
                anchor = L.point(options.iconAnchor);
            }

            if (!anchor && size) {
                anchor = size.divideBy(2, true);
            }

            img.className = options.className;

            if (anchor) {
                img.style.marginLeft = (-anchor.x) + 'px';
                img.style.marginTop  = (-anchor.y) + 'px';
            }

            if (size) {
                img.style.width  = size.x + 'px';
                img.style.height = size.y + 'px';
            }
        },

        createShadow: function () {
            /* var div = document.createElement('div');

            this._setIconStyles(div, 'shadow');
            return div; */
        }
    });
        
    L.AwesomeMarkers.icon = function (options) {
        return new L.AwesomeMarkers.Icon(options);
    };

}(this, document));