var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("conditional-wrapper", { attrs: { show: _vm.wrap } }, [
    _c(
      "span",
      { ref: "iconWrapper", staticClass: "icon", class: _vm.sizeClass },
      [
        !_vm.slash
          ? _c("i", { class: _vm.sizedIcon })
          : _c("span", { staticClass: "fa-stack", class: _vm.slashSize }, [
              _c("i", { class: _vm.sizedIcon }),
              _vm._v(" "),
              _vm.slash
                ? _c("i", {
                    staticClass: "fas fa-slash fa-stack-1x fa-inverse",
                    attrs: { "data-fa-transform": "down-2.5" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.slash
                ? _c("i", { staticClass: "fas fa-slash fa-stack-1x" })
                : _vm._e(),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }