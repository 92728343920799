var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "map-wrapper",
      staticStyle: { padding: "0", position: "relative" },
    },
    [
      _c("loader-component", {
        attrs: { show: _vm.loaderActive, loader_type: "local" },
      }),
      _vm._v(" "),
      _c("div", {
        ref: "mapContainer",
        style: `width: ${_vm.width}; height: ${_vm.height};`,
      }),
      _vm._v(" "),
      _c(
        "tooltip-component",
        {
          ref: "tooltip",
          attrs: {
            trigger: "manual",
            "to-element": _vm.tooltipTarget,
            theme: _vm.tooltipTheme,
            arrow: false,
          },
        },
        [
          !!_vm.tooltipIcon
            ? _c(
                "icon-component",
                _vm._b({}, "icon-component", _vm.tooltipIcon, false)
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.tooltipData) } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button is-round is-dark is-floating",
          attrs: {
            id: "zoom-in-button",
            disabled: _vm.currentZoom === _vm.realZoomBounds[1],
          },
          on: {
            click: function ($event) {
              return _vm.zoom("in")
            },
          },
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button is-round is-dark is-floating",
          attrs: {
            id: "zoom-out-button",
            disabled: _vm.currentZoom === _vm.realZoomBounds[0],
          },
          on: {
            click: function ($event) {
              return _vm.zoom("out")
            },
          },
        },
        [_vm._m(1)]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-plus" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-minus" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }